<template>
  <!-- Footer Start -->
  <div class="footer">
    <div class="footer-links">
      <div
        class="container"
        v-show="$route.name != 'Checkout'"
        v-if="footerData && totalColumns"
      >
        <div class="row q-col-gutter-x-md">
          <div
            :class="
              footerData?.colCount === 0
                ? `col-12 col-md-3`
                : `col-12 col-md-${12 / totalColumns}`
            "
            v-if="footerData?.enableBusinessDetailsInFooter"
          >
            <div class="footer-block">
              <h5>
                <img
                  v-if="theme && theme.logoURL"
                  :src="theme.logoURL"
                  @click="goToHomePage"
                />
                <label
                  v-else
                  class="q-my-none logo-title"
                  v-text="businessConfigurations?.businessName || ''"
                  @click="goToHomePage"
                />
              </h5>
              <div class="footer-content">
                <ul>
                  <li
                    ><a
                      class="flex nowrap"
                      :href="`tel:${businessConfigurations.phone}`"
                      ><q-icon name="phone" class="col-auto q-mr-sm" /><span
                        class="col"
                        >{{ businessConfigurations?.phone }}</span
                      ></a
                    ></li
                  >
                  <li
                    ><a
                      class="flex nowrap"
                      :href="`mailto:${businessConfigurations.email}?subject=Customer Support`"
                      ><q-icon name="email" class="col-auto q-mr-sm" /><span
                        class="col"
                        >{{ businessConfigurations?.email }}</span
                      ></a
                    ></li
                  >
                  <li
                    ><a class="flex nowrap" :href="'find-store'"
                      ><q-icon name="map" class="col-auto q-mr-sm" /><span
                        class="col"
                        >Store Locator</span
                      ></a
                    ></li
                  >
                  <li v-if="storeHasLnsSubscription">
                    <a
                      class="flex nowrap items-center cursor-pointer"
                      clickable
                      @click="showLNS"
                    >
                      <SvgIcon
                        size="1em"
                        icon="loyalty-o"
                        style="margin-right: 8px"
                      /><span class="col">Loyalty Program</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div
            :class="
              footerData?.colCount === 0
                ? `col-12 col-md-3`
                : `col-12 col-md-${12 / totalColumns}`
            "
            v-if="footerData?.enableGetInTouchInFooter"
          >
            <div class="footer-block">
              <h5>Get in Touch</h5>
              <div class="footer-content">
                <ul>
                  <li><a :href="'customer-service'">Customer Support</a></li>
                  <li
                    ><a
                      :href="`tel:${businessConfigurations.customerServicePhone}`"
                      >{{ businessConfigurations?.customerServicePhone }}</a
                    ></li
                  >
                  <li
                    ><a
                      :href="`mailto:${businessConfigurations.customerServiceEmail}?subject=Customer Support`"
                      >{{ businessConfigurations?.customerServiceEmail }}</a
                    ></li
                  >
                </ul>
              </div>
            </div>
            <div
              class="footer-block"
              v-if="
                footerData?.enableSocialPlatformsInFooter &&
                socialPlatforms?.length !== 0
              "
            >
              <h5>Connect With Us</h5>
              <div class="footer-content">
                <ul v-if="socialPlatforms" class="inline-flex footer-social">
                  <li
                    v-for="(socialPlatform, index) in socialPlatforms"
                    :key="`${socialPlatform.platform}${index}`"
                    class="q-mr-sm"
                  >
                    <a :href="socialPlatform.link" target="_blank">
                      <SvgIcon size="24px" :icon="socialPlatform.icon" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div
            v-for="(column, index) in footerData.columns"
            :key="index"
            :class="`col-6 col-md-${12 / totalColumns}`"
          >
            <template v-if="column.rows && column.rows.length">
              <div
                class="footer-block"
                v-for="(row, index) in column.rows"
                :key="`${row.menuID}-${index}`"
              >
                <h5 v-html="row.menuName" />
                <div class="footer-content">
                  <ul v-if="row.menuItems && row.menuItems.length">
                    <li
                      v-for="(menuItem, index) in row.menuItems"
                      :key="`${menuItem.menuID}-${index}`"
                    >
                      <a
                        v-if="menuItem.type == 'link' && menuItem.link"
                        :href="menuItem.link"
                        target="_blank"
                        v-html="menuItem.title"
                      />
                      <router-link
                        :target="menuItem.openInNew ? '_blank' : '_self'"
                        :to="`/pages/${menuItem.slug}`"
                        v-if="menuItem.type == 'page' && menuItem.slug"
                        v-html="menuItem.title"
                      />
                      <router-link
                        :target="menuItem.openInNew ? '_blank' : '_self'"
                        :to="`/${menuItem.appPageSlug}`"
                        v-if="
                          menuItem.type == 'app-page' && menuItem.appPageSlug
                        "
                        v-html="menuItem.title"
                      />
                      <router-link
                        :target="menuItem.openInNew ? '_blank' : '_self'"
                        :to="`/pages/legal/${getPageSlug(
                          menuItem.appPageSlug
                        )}`"
                        v-if="menuItem.type == 'legal' && menuItem.appPageSlug"
                        v-html="menuItem.title"
                      />

                      <p
                        v-if="menuItem.type == 'text'"
                        v-html="menuItem.title"
                      />
                      <small
                        v-if="menuItem.type == 'text'"
                        v-html="menuItem.description"
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <!-- Section: Disclaimer Start -->
      <DisclaimerInfo
        v-if="businessConfigurations?.showDisclaimer"
        v-show="$route.name != 'Checkout'"
      />
      <!-- Section: Disclaimer End -->
    </div>

    <div class="footer-copyright">
      <div class="container">
        <div class="row items-center">
          <div class="col-12 col-md-6">
            &copy; {{ new Date().getFullYear() }} FasTraxPOS
            LLC&nbsp;|&nbsp;Powered by
            <a href="https://goftx.com/" class="companyname" target="_blank">
              FasTrax Solutions </a
            >&nbsp;|&nbsp;<router-link
              class="cursor-pointer"
              to="/footer-links/privacy-policy"
              >PrivacyPolicy</router-link
            >
          </div>
          <div class="col-12 col-md-6 text-right">
            <!-- Section: We Accept Start -->
            <SectionWeAccept>
              <template v-slot:skeleton>
                <div class="container q-pa-md">
                  <q-list dense class="row items-center justify-right">
                    <q-item-label class="col-12 title col-md-auto">
                      <q-skeleton
                        type="text"
                        width="100px"
                        height="30px"
                        class="text-subtitle1 q-mr-lg"
                      />
                    </q-item-label>
                    <q-item class="col-12 paymentAcceptIcons col-md-auto">
                      <q-skeleton
                        type="rect"
                        height="32px"
                        width="50px"
                        class="q-mr-sm"
                        v-for="n in 6"
                        :key="n"
                      />
                    </q-item>
                  </q-list>
                </div>
              </template>
            </SectionWeAccept>
            <!-- Section: We Accept End -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Footer',
  components: {
    SectionWeAccept: () => import('components/payment/SectionWeAccept.vue'),
    DisclaimerInfo: () => import('components/mobile-footer/DisclaimerInfo.vue'),
  },
  data() {
    return {
      legalObj: {
        customerReviewPolicy: {
          title: 'Review Policy',
          seoName: 'review-policy',
        },
        privacyPolicy: { title: 'Privacy Policy', seoName: 'privacy-policy' },
        refundPolicy: { title: 'Refund Policy', seoName: 'refund-policy' },
        termsOfService: { title: 'Terms Of Service', seoName: 'terms-service' },
      },
    }
  },
  computed: {
    ...mapGetters('theme', ['theme']),
    ...mapGetters('category', ['trendingCategories']),
    ...mapGetters('common', [
      'footerData',
      'socialPlatforms',
      'storeHasLnsSubscription',
    ]),
    totalColumns() {
      return this.footerData.colCount +
        (this.footerData?.enableBusinessDetailsInFooter ? 1 : 0) +
        (this.footerData?.enableGetInTouchInFooter ? 1 : 0) ===
        5
        ? (this.footerData.colCount +
            (this.footerData?.enableBusinessDetailsInFooter ? 1 : 0) +
            (this.footerData?.enableGetInTouchInFooter ? 1 : 0)) *
            0.1
        : this.footerData.colCount +
            (this.footerData?.enableBusinessDetailsInFooter ? 1 : 0) +
            (this.footerData?.enableGetInTouchInFooter ? 1 : 0)
    },
  },
  methods: {
    getPageSlug(slug) {
      if (this.legalObj[slug]) {
        return this.legalObj[slug]['seoName']
      } else return slug
    },
    goToHomePage(event) {
      if (event.ctrlKey) {
        let routeData = this.$router.resolve({ name: 'Home' })
        window.open(routeData.href, '_blank')
      } else if (this.$router.currentRoute.name != 'Home')
        this.$router.push({ name: 'Home' }).catch(() => {})
    },
    showLNS() {
      if (!this.isLoggedIn) {
        this.changeDialogState({
          dialog: 'accountDrawer',
          val: true,
        })
      } else if (!this.isCordova) {
        this.$router.push({ path: '/loyalty-program#/' }).catch(() => {})
      }
    },
  },
}
</script>
<style scoped lang="scss">
// .footer-social{
//   list-style-type: none;
//   margin: 0;
//   margin-left: auto;
//   float: right;
//   a{
//     opacity: 0.7;
//       &:hover {
//         opacity: 1;
//       }
//   }
// }
.footer {
  font-size: 14px;
  color: #fff;
  letter-spacing: 0.2px;
  .container {
    max-width: 1280px;
  }
  .footer-links {
    background: #111111;
    padding: 15px 0;
    @media (min-width: 1024px) {
      padding: 15px;
    }
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  a {
    text-decoration: none;
    color: #888888;
    &:hover {
      color: #fff;
    }
  }
  h5 {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    /*text-transform: uppercase;*/
    color: #fff;
  }
  p {
    margin: 0;
    padding: 0;
  }
  .footer-block {
    padding: 15px 0;
    .footer-content {
      padding-top: 8px;
      line-height: 20px;
      color: #888888;
    }
    li {
      padding: 6px 0;
      word-break: break-word;
    }
    p {
      word-break: break-word;
    }
  }
  .footer-copyright {
    padding: 15px;
    background: #000000;
    color: #888888;
    // padding-left: 140px;
    @media (max-width: 599px) {
      span {
        visibility: hidden;
        display: block;
        height: 5px;
      }
    }
  }
}
</style>
