<template>
  <q-dialog v-model="dialogState" maximized position="bottom">
    <q-card style="min-height: 80vh" class="mobileCardDialogue">
      <div class="mobileDialogTitle">
        <h5 class="text-h5">Offers</h5>
        <q-icon class="close cursor-pointer" name="close" v-close-popup />
      </div>
      <div class="mobileDialogContent">
        <q-scroll-area style="max-width: 100%; height: calc(80vh - 70px)">
          <q-list v-for="(offer, index) in properties.offers" :key="index">
            <q-item class="q-px-none">
              <q-item-section>
                <q-item-label>{{ offer.displayName }}</q-item-label>
              </q-item-section>
              <q-item-section side>
                <q-btn
                  color="primary"
                  size="sm"
                  label="See How"
                  :to="{
                    name: 'DiscountDetail',
                    params: { seoName: offer.discountID },
                  }"
                />
              </q-item-section>
            </q-item>
            <q-separator spaced />
          </q-list>
        </q-scroll-area>
      </div>
    </q-card>
  </q-dialog>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'OffersModal',
  props: {
    AllOfferAndDeals: {
      type: Array,
      default: () => {
        return new Array()
      },
    },
    selectedOfferIndex: {
      type: Number,
      default: 0,
    },
    initialCategoryId: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters('showcase', ['getDialogState']),
    dialogState: {
      get() {
        return this.getDialogState('OffersModal')
      },
      set(val) {
        this.changeDialogState({
          dialog: 'OffersModal',
          val,
          properties: {
            offers: null,
          },
        })
      },
    },
    properties() {
      return this.getDialogProperties('OffersModal')
    },
  },
  data() {
    return {
      selectedCategoryId: this.initialCategoryId,
    }
  },
  methods: {},
}
</script>
<style lang="scss">
.mobileDialogContent {
  .filterCategory {
    padding: 0 16px 16px 0;
    .q-tree__node-header {
      padding-left: 0 !important;
    }
    .q-item {
      padding: 0;
      min-height: 31px;
      .q-item__section--avatar {
        min-width: 30px;
        padding-right: 10px;
      }
    }
    .q-tree__arrow {
      order: 2;
    }
    .q-focus-helper {
      display: none;
    }
    .q-tree__node-header {
      padding-left: 0;
    }
  }
}
</style>
