<template>
  <q-toolbar class="betaMode bg-secondary text-center justify-center q-pa-xs">
    <p class="q-ma-none flex justify-center no-wrap items-center full-width">
      <span
        v-show="[2, 3].includes(mode)"
        class="text-weight-bold q-mr-xs"
        v-text="mode == 2 ? 'TEST:' : 'BETA:'"
      ></span>

      <span :class="[{ ellipsis: isMobile }]" v-if="modeDescription">
        {{ modeDescription.length > 125 ? shortDescription : modeDescription }}

        <span
          class="q-ma-none float-right no-wrap"
          :class="{ 'q-ml-lg': shortDescription.length > 0 }"
          v-if="mode == 5 && !showMoreBtn && !isMobile"
        >
          Closing On:
          {{ businessConfigurations.closeDate | formatDate }}
        </span>
      </span>
      <span v-else-if="!shortDescription && [2, 3, 5].includes(mode)">
        <template v-if="mode == 2">Test mode enabled</template>
        <template v-else-if="mode == 3">Beta mode enabled</template>
        <span class="q-ma-none float-right no-wrap" v-else-if="mode == 5">
          Closing On:
          {{ businessConfigurations.closeDate | formatDate }}
        </span>
      </span>
      <q-btn
        dense
        unelevated
        no-caps
        no-wrap
        label="See details"
        class="text-underline"
        v-if="showMoreBtn"
        @click="betaMode = true"
      />
    </p>
    <q-dialog
      v-model="betaMode"
      maximized
      :seamless="!isMobile"
      content-class="betaMode__dialog"
      :position="isMobile ? 'bottom' : 'top'"
    >
      <q-card flat class="bg-secondary text-white full-width">
        <q-card-section class="row items-center justify-center no-wrap">
          <div class="col-8" v-if="[2, 3].includes(mode)">
            <div class="container q-pt-md q-pt-md-none">
              <div
                class="row items-start"
                :class="$q.screen.lt.md ? 'q-col-gutter-xs' : 'q-col-gutter-lg'"
              >
                <div
                  class="col-12 col-md-3"
                  :class="[{ 'text-right': $q.screen.gt.sm }]"
                >
                  <SvgIcon
                    size="5rem"
                    :icon="mode == 2 ? 'test-mode' : 'beta-mode'"
                    class="text-secondary"
                  />
                </div>
                <div class="col col-md word-break-word">
                  <span
                    class="text-weight-bold q-mr-xs"
                    v-text="mode == 2 ? 'TEST:' : 'BETA:'"
                  ></span>
                  {{
                    modeDescription
                      ? modeDescription
                      : mode == 2
                      ? 'Test mode enabled'
                      : 'Beta mode enabled'
                  }}
                  <br />
                  <q-btn
                    dense
                    unelevated
                    no-caps
                    label="Yes, Got it"
                    class="text-underline text-body1 text-weight-bold no-hover q-mt-lg q-mt-md-sm q-mb-md q-mb-md-none"
                    padding="0"
                    @click="betaMode = false"
                  />
                </div>
                <div class="col-md-3 q-pa-none"></div>
              </div>
            </div>
          </div>
          <div class="col-12" v-else>
            <div class="container q-pt-md q-pt-md-none">
              <div
                class="row items-start"
                :class="$q.screen.lt.md ? 'q-col-gutter-xs' : 'q-col-gutter-lg'"
              >
              <div
                  class="col-12 col-md-3"
                  :class="[{ 'text-right': $q.screen.gt.sm }]"
                >
                  <SvgIcon
                    size="5rem"
                    icon="closing-soon"
                    class="text-secondary"
                  />
                </div>

                <div class="col col-md word-break-word">
                  <span
                    class="text-weight-bold q-mr-xs"
                  >CLOSING SOON: </span> {{ modeDescription }}
                  <br />
                  <p
                    class="text-weight-bold q-my-none q-mt-md"
                    v-if="mode == 5"
                  >
                    Closing On:
                    {{ businessConfigurations.closeDate | formatDate }}
                  </p>
                  <q-btn
                    dense
                    unelevated
                    no-caps
                    label="Yes, Got it"
                    class="text-underline text-body1 text-weight-bold no-hover q-mt-lg q-mt-md-sm q-mb-md q-mb-md-none"
                    padding="0"
                    @click="betaMode = false"
                  />
                </div>
                <div class="col-md-3 q-pa-none"></div>
              </div>
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </q-toolbar>
</template>

<script>
export default {
  name: 'ModeHeader',
  data() {
    return {
      betaMode: false,
    }
  },
  props: {
    mode: {
      type: Number,
    },
  },
  computed: {
    modeDescription() {
      return (
        (this.businessConfigurations &&
          this.businessConfigurations?.modeDescription) ||
        ''
      )
    },
    shortDescription() {
      return this.modeDescription
        ? this.modeDescription.slice(0, 125) + '...'
        : ''
    },
    showMoreBtn() {
      let isShow = false
      if (this.isMobile) {
        if ([2, 3].includes(this.mode)) isShow = true
        else if (this.mode == 5 && this.modeDescription) isShow = true
      } else if (this.modeDescription.length > 125) isShow = true
      return isShow
    },
  },
}
</script>
<style lang="scss">
.betaMode {
  min-height: 32px;
  border-bottom: 1px solid #ffffff33;
  @media (max-width: 1023px) {
    position: fixed;
    bottom: 72px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 10px 15px;
    &__dialog {
      text-align: center;
      .q-dialog__inner {
        right: 15px;
        left: 15px;
        bottom: 5px;
      }
    }
  }
}
</style>
