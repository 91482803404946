var render = function render(){var _vm=this,_c=_vm._self._c;return _c('q-footer',[_c('q-list',_vm._l((_vm.mobileFooterLinks),function(footerLink,index){return _c('q-item',{directives:[{name:"show",rawName:"v-show",value:(
        !footerLink.hasOwnProperty('checkGetter') ||
        (footerLink.checkGetter && _vm.$store.getters[footerLink.checkGetter])
      ),expression:"\n        !footerLink.hasOwnProperty('checkGetter') ||\n        (footerLink.checkGetter && $store.getters[footerLink.checkGetter])\n      "}],key:`${footerLink.id}-${index}`,attrs:{"to":footerLink.link ? { name: footerLink.link } : null,"clickable":""},on:{"click":function($event){return _vm.onClickFooterLink(footerLink)}}},[_c('q-item-section',[_c('q-item-label',[(footerLink.counter && _vm.cartTotalItems)?_c('span',{staticClass:"counter shopping-cart-footer",domProps:{"textContent":_vm._s(_vm.cartTotalItems < 100 ? _vm.cartTotalItems : '99+')}}):_vm._e(),(footerLink.id == 'account' && _vm.isLoggedIn)?[_c('UserAvatar')]:[_c('SvgIcon',{attrs:{"flat":"","dense":"","color":"white","icon":footerLink.icon}})]],2),_c('q-item-label',{attrs:{"caption":""}},[_c('div',{class:footerLink.id == 'account' && _vm.isLoggedIn
                ? 'f-account ellipsis'
                : ''},[_vm._v("\n            "+_vm._s(footerLink.id == 'account' && _vm.isLoggedIn
                ? _vm.currentUserName
                : footerLink.title)+"\n          ")])])],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }