<template>
  <q-btn
    flat
    dense
    class="h-search--list q-pl-sm q-pr-sm"
    color="white"
    text-color="black"
    :ripple="false"
    @click="toggleDialog"
  >
    <SvgIcon
      className="q-ml-sm q-mr-sm"
      color="grey"
      size="1rem"
      icon="search-with-list"
    />
    <template v-if="currentLocationId">
      <q-dialog
        v-if="isMobile"
        v-model="openDialog"
        position="bottom"
        maximized
      >
        <q-card class="mobileCardDialogue">
          <SearchWithListContent :toggleDialog="toggleDialog" />
        </q-card>
      </q-dialog>

      <q-popup-edit
        v-else
        v-model="openPopup"
        :cover="false"
        ref="popupEdit"
        :offset="[0, 10]"
        self="top middle"
      >
        <SearchWithListContent :toggleDialog="toggleDialog" />
      </q-popup-edit>
    </template>
  </q-btn>
</template>

<script>
export default {
  components: {
    SearchWithListContent: () => import('./SearchWithListContent'),
  },
  data() {
    return {
      openPopup: false,
      openDialog: false,
    }
  },
  methods: {
    toggleDialog() {
      if (this.currentLocationId) {
        if (this.isMobile) this.openDialog = !this.openDialog
        else if (this.$refs.popupEdit) this.$refs.popupEdit.hide()
      }
    },
  },
}
</script>
