var render = function render(){var _vm=this,_c=_vm._self._c;return _c('q-layout',{class:[
    _vm.scrollClass,
    _vm.showLocationHeader ? 'hasLocationHeader' : '',
    _vm.showAgeVerificationBtn ? 'show-age-verification' : '',
  ],attrs:{"view":!_vm.isMobile ? 'lHh Lpr lfr' : 'lHh lpr lFf'}},[_c('Header',{ref:"headerBox"}),(_vm.$route.name == 'Home' && _vm.isMobile)?_c('MobileFooter'):_vm._e(),_c('q-page-container',{class:{
      pinCart:
        _vm.isCartSectionPined && _vm.cartDrawerPinedPage.includes(_vm.currentRouteName),
    }},[(_vm.$route.name == 'Home' && !_vm.isMobile)?_c('SubHeader'):_vm._e(),(!_vm.isMobile)?_c('q-scroll-area',{ref:"mainScrollArea",staticClass:"container-scroll-area",style:({
        height:
          _vm.$route.name === 'Home' && !_vm.isMobile
            ? `calc(100vh - (${_vm.headerHight}px + 50px))`
            : `calc(100vh - (${_vm.headerHight}px))`,
      }),attrs:{"thumb-style":{ ..._vm.scrollAreaThumbStyle, zIndex: 2000 },"bar-style":{ ..._vm.scrollAreaBarStyle, zIndex: 2000 },"visible":_vm.scrollAreaVisible},on:{"scroll":function($event){return _vm.$root.$emit('main-container-scroll')}}},[_c('PageContainer')],1):_c('div',[_c('PageContainer')],1)],1),_c('q-no-ssr',[(_vm.currentLocationId)?[(!_vm.isLoggedIn || (_vm.isLoggedIn && _vm.customerExists))?[(_vm.isSlotBookingRequired)?_c('ReserveTimeSlot',{attrs:{"location":_vm.currentLocation}}):_vm._e(),_c('AddAddressDialog'),_c('CMSDialog')]:_vm._e(),_c('CategoryDrawer'),_c('DeliveryInfoModal'),_c('QuickProductDetailDialog'),(_vm.isMobile)?_c('MobileSearchDialog',{attrs:{"currentLocationId":_vm.currentLocationId}}):_vm._e(),_c('ChangeLocationConfirm'),_c('LNSCouponsDialog'),_c('UpdateProductQuantityDialog')]:_vm._e(),_c('OffersModal'),_c('WishlistDialog'),_c('AccountDrawer')],2),(!_vm.isMobile)?_c('CartDrawer'):_vm._e(),_c('q-no-ssr',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.showcase.dialog.categoryDrawer ||
        _vm.showcase.dialog.accountDrawer ||
        (_vm.showcase.dialog.cartDrawer &&
          !(
            _vm.isCartSectionPined &&
            _vm.cartDrawerPinedPage.includes(_vm.currentRouteName)
          ))
      ),expression:"\n        showcase.dialog.categoryDrawer ||\n        showcase.dialog.accountDrawer ||\n        (showcase.dialog.cartDrawer &&\n          !(\n            isCartSectionPined &&\n            cartDrawerPinedPage.includes(currentRouteName)\n          ))\n      "}],staticClass:"mainLayoutBackdrop",on:{"click":function($event){return _vm.changeDialogState({
          dialog: _vm.showcase.dialog.categoryDrawer
            ? 'categoryDrawer'
            : _vm.showcase.dialog.cartDrawer
            ? 'cartDrawer'
            : 'accountDrawer',
          val: false,
        })}}}),(!_vm.isMobile)?_c('AppBarcode'):_vm._e(),(_vm.businessConfigurations.isStoreAgeRestricted)?[_c('PersonalInfoDialog'),_c('EAIVSpinner'),_c('AgeVerificationBtn',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAgeVerificationBtn),expression:"showAgeVerificationBtn"}]}),_c('MismatchPersonalInfo'),_c('AskForSignup')]:_vm._e(),_c('VerificationFailDialog'),_c('SetDateTimeDialog')],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }