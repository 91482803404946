<template>
  <q-drawer
    :class="['sidebarCart']"
    side="right"
    v-model="drawerState"
    bordered
    :overlay="!isCartSectionPined || !cartDrawerPinedPage.includes($route.name)"
    content-class="bg-white"
    :width="width"
    :no-swipe-backdrop="isPWA"
    :no-swipe-open="isPWA"
    :no-swipe-close="isPWA"
  >
    <div class="q-cartSidebar">
      <div class="sidebarHeader">
        <div class="row items-center">
          <div class="col ellipsis">
            <a
              href="javascript:void(0)"
              @click="toggleCartPin"
              class="pinned"
              :class="{ active: isCartSectionPined }"
              v-if="!isMobile"
              v-show="cartDrawerPinedPage.includes($route.name)"
            >
              <SvgIcon className="q-mr-sm" size="1rem" icon="pinned" />
              <span>
                {{ `${isCartSectionPined ? 'Unpin' : 'Pin'} this section` }}
              </span>
            </a>
            <span v-if="isMobile">My Cart</span>
          </div>
          <div class="col-auto">
            <q-btn
              dense
              flat
              @click="
                () => {
                  if (
                    this.isCartSectionPined &&
                    cartDrawerPinedPage.includes(this.$route.name)
                  ) {
                    this.toggleCartPin()
                  }
                  this.changeDialogState({
                    dialog: 'cartDrawer',
                    val: false,
                  })
                }
              "
            >
              <q-icon name="close" />
            </q-btn>
          </div>
        </div>
      </div>
    </div>

    <div v-if="currentCartDetails?.orderID">
      <q-scroll-area
        class="cartDrawerHeight"
        :thumb-style="scrollAreaThumbStyle"
        :bar-style="scrollAreaBarStyle"
        :visible="scrollAreaVisible"
        ref="cartScrollArea"
        :style="{
          maxWidth: '100%',
          height: `calc(100vh - ${topBottomHeight}px)`,
        }"
      >
        <q-list
          padding
          class="rounded-borders sidebarCartAccordion"
          v-if="currentCartDetails?.fulfillments"
        >
          <div
            v-for="(fulFillment, key, index) in currentCartDetails.fulfillments"
            :key="`${fulFillment.fulfillmentMethodID}-${key}-${index}`"
          >
            <q-expansion-item dense-toggle :default-opened="true">
              <template v-slot:header>
                <q-item-section class="toggleIcon" avatar>
                  <SvgIcon :icon="fulFillment.codeName" />
                </q-item-section>
                <q-item-section class="toggleTitle">
                  {{ fulFillment.displayName }} ({{ fulFillment.totalQTY }})
                </q-item-section>
              </template>

              <q-card>
                <q-card-section class="no-padding">
                  <q-list class="cartList">
                    <CartLineItem
                      v-for="(
                        shoppingCartItem, key, index
                      ) in fulFillment.shoppingCartItems"
                      :key="`${shoppingCartItem.orderLineItemID}-${key}-${index}`"
                      :shoppingCartItem="shoppingCartItem"
                      :fulFillmentId="fulFillment.fulfillmentMethodID"
                      :fulFillmentCodeName="fulFillment.codeName"
                      :onCartDrawer="true"
                    />
                  </q-list>
                </q-card-section>
              </q-card>
            </q-expansion-item>
            <!-- <q-item class="cartTotal">
              <q-item-section>
                {{ fulFillment.totalQTY }} items
              </q-item-section>
              <q-item-section side class="cart-subTotal">
                <template v-if="false">
                  {{ fulFillment.displayName }} Sub Total:
                </template>
                Sub Total:

                <span
                  class="text-strike strike-price"
                  v-if="
                    fulFillment.subTotal != fulFillment.totalWithDiscount &&
                    false
                  "
                >
                  {{ fulFillment.subTotal | currency }}
                </span>
                <span class="text-positive">
                  {{
                    fulFillment.subTotal || fulFillment.totalAmount | currency
                  }}
                </span>
              </q-item-section>
            </q-item> -->
            <!-- <MinimumCheckoutError
              :errorId="`${fulFillment.fulfillmentMethodID}-drawer-error`"
              :fulFillment="fulFillment"
              :orderSummary="currentCartDetails.orderSummary"
            /> -->
          </div>
        </q-list>
      </q-scroll-area>
      <div
        class="cartDrawerBtn q-pa-md q-pt-lg q-pb-md q-px-sm"
        ref="cartBottom"
      >
        <div class="row column">
          <!-- <div class="col-xs-12 cartDrawerTotal">
            <q-expansion-item
              v-if="
                currentCartDetails.orderSummary &&
                ((currentCartDetails.orderSummary.additionalCharges &&
                  currentCartDetails.orderSummary.additionalCharges.length) ||
                  currentCartDetails.orderSummary.taxes ||
                  currentCartDetails.orderSummary.discount ||
                  currentCartDetails.orderSummary.buydown)
              "
              dense
              expand-separator
              @after-show="calcartTopBottomHeight"
              @after-hide="calcartTopBottomHeight"
              :expand-icon-class="['rotate-180']"
            >
              <template v-slot:header>
                <q-item-section class="text-center" />
              </template>
              <q-card-section>
                <div class="cartDrawerTotalItem">
                  <template
                    v-if="
                      currentCartDetails.orderSummary?.additionalCharges &&
                      currentCartDetails.orderSummary.additionalCharges.length
                    "
                  >
                    <p
                      v-for="(additionalCharge, index) of currentCartDetails
                        .orderSummary.additionalCharges"
                      :key="`${additionalCharge.chargeName}-${index}`"
                    >
                      {{ additionalCharge.chargeName }}
                      <strong class="text-primaryOnBody">
                        {{
                          additionalCharge.chargeAmount | currency(false, true)
                        }}
                      </strong>
                    </p>
                  </template>
                  <p v-if="currentCartDetails.orderSummary?.otherTax">
                    Tax
                    <strong class="text-primaryOnBody">
                      {{
                        currentCartDetails.orderSummary.otherTax
                          | currency(false, true)
                      }}
                    </strong>
                  </p>
                  <p v-if="currentCartDetails.orderSummary?.occupationalTax">
                    Occupational Tax
                    <strong class="text-primaryOnBody">
                      {{
                        currentCartDetails.orderSummary.occupationalTax
                          | currency(false, true)
                      }}
                    </strong>
                  </p>
                  <p v-if="currentCartDetails.orderSummary?.buydown">
                    Other Discount
                    <strong class="text-primaryOnBody">
                      -
                      {{
                        currentCartDetails.orderSummary?.buydown
                          | currency(false)
                      }}
                    </strong>
                  </p>

                  <p v-if="currentCartDetails.orderSummary.discount">
                    You Saved
                    <strong class="text-primaryOnBody">
                      {{
                        currentCartDetails.orderSummary.discount
                          | currency(false)
                      }}
                    </strong>
                  </p>
                </div>
              </q-card-section>
            </q-expansion-item>
          </div> -->
          <div class="col-xs-12">
            <p>
              Sub Total
              <q-btn
                unelevated
                no-caps
                flat
                round
                dense
                padding="0"
                class="q-ml-xs vertical-top mt-2"
              >
                <SvgIcon size="14px" icon="info-outline" />
                <q-tooltip
                  anchor="top middle"
                  self="bottom middle"
                  :offset="[10, 10]"
                  content-class="bg-grey-10"
                  content-style="font-size: 14px"
                  >Taxes and discounts will be <br />calculated on the cart
                  page.</q-tooltip
                >
              </q-btn>
              <strong class="text-tertiary">
                {{ currentCartDetails.subTotalDisplay | currency }}
                <!-- {{ currentCartDetails.orderSummary.subTotal | currency }} -->
              </strong>
            </p>
          </div>
          <div class="col-xs-12 text-center">
            <q-btn
              no-caps
              rounded
              unelevated
              :label="
                $route.name == 'Cart'
                  ? 'Proceed to Checkout'
                  : 'Proceed To Cart'
              "
              padding="sm"
              color="secondaryOnBody"
              class="q-pr-md q-pl-md full-width"
              @click="proceedCheckout"
              :disable="businessConfigurations.mode === 5"
            />
          </div>
        </div>
      </div>
    </div>
    <InnerLoadingSpinner :showing="loading" v-else-if="loading" />
    <q-scroll-area
      :thumb-style="scrollAreaThumbStyle"
      :bar-style="scrollAreaBarStyle"
      :visible="scrollAreaVisible"
      style="max-width: 100%; height: calc(100vh - 210px)"
      v-else
    >
      <EmptyComponent
        image="images/empty-cart.png"
        :btnText="
          isCartSectionPined && cartDrawerPinedPage.includes($route.name)
            ? ''
            : 'Continue Shopping'
        "
        :btnClick="
          () => {
            this.changeDialogState({
              dialog: 'cartDrawer',
              val: false,
            })
          }
        "
      >
        <p>Your cart is currently empty</p>
      </EmptyComponent>
    </q-scroll-area>
  </q-drawer>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  validateMinCheckoutAmount,
  cartHasFulfillment,
  getOffset,
  hideShowFullSpinner,
} from 'src/utils'
import IdentityMixin from 'src/utils/IdentityMixin'
import { cartDrawerPinedPage } from 'src/constants'

export default {
  name: 'CartDrawer',
  mixins: [IdentityMixin],
  components: {
    CartLineItem: () => import('components/cart/CartLineItem'),
    // MinimumCheckoutError: () => import('components/cart/MinimumCheckoutError'),
  },
  computed: {
    ...mapGetters('cart', ['currentCartDetails', 'loading']),
    ...mapGetters('reserveSlot', ['customerSPSlotId', 'customerSCDSlotId']),
    ...mapGetters('persisted', [
      'eaivPersistedSessionId',
      'eaivPersistedVerificationSuccess',
    ]),
    ...mapGetters('persistedLocal', ['isCartSectionPined']),
    ...mapGetters('common', ['checkSlotBookingRequired']),
    ...mapGetters('customer', ['customer']),
    ...mapGetters('address', ['getAddressByCustomerAddressID']),
    ...mapGetters('ageVerification', ['isOrderVerified']),
    drawerState: {
      get() {
        return (
          (this.isCartSectionPined &&
            this.cartDrawerPinedPage.includes(this.$route.name)) ||
          this.$store.state.showcase.dialog.cartDrawer
        )
      },
      set(val) {
        if (val != this.drawerState)
          this.changeDialogState({
            dialog: 'cartDrawer',
            val,
          })
      },
    },
    width: {
      get() {
        return this.$store.state.showcase.drawerWidth
      },
    },
    checkoutBtnName() {
      return this.businessConfigurations?.isStoreAgeRestricted &&
        this.businessConfigurations?.enableAgeRestrictedCheckout &&
        this.currentCartDetails?.ageRequired > 0 &&
        !this.isOrderVerified
        ? 'Verify Age and Checkout'
        : 'Proceed to Checkout'
    },
    getRedirectUri() {
      let uri = ''
      if (this.isCordova) {
        uri = process.env.ANDROID_DEEP_LINK
      } else {
        uri = Object.keys(this.$route.query).length
          ? window.location.href
          : window.location.origin + window.location.pathname
      }
      return uri
    },
    getEAIVExternalURL() {
      return process.env.EAIV_ExternalURL
    },
  },
  data() {
    return {
      cartDrawerPinedPage,
      topBottomHeight: 0,
      text: 1,
    }
  },
  created() {
    this.$root.$on('hide-header', () => {
      this.calcartTopBottomHeight()
    })
  },
  updated() {
    this.calcartTopBottomHeight()
  },
  mounted() {
    this.setHeaderHeight()
  },
  methods: {
    calcartTopBottomHeight() {
      let offset,
        bottomAreaHeight = 0

      if (this.$refs.cartScrollArea)
        offset = getOffset(this.$refs.cartScrollArea.$el)

      if (this.$refs.cartBottom)
        bottomAreaHeight = this.$refs.cartBottom.clientHeight

      this.topBottomHeight =
        (offset && offset.top ? offset.top : 0) + bottomAreaHeight
    },
    proceedCheckout() {
      // if (!this.isLoggedIn) return this.loginWithIdentity()

      if (this.$route.name === 'Cart') {
        if (this.loading) return

        let isValidCheckoutAmount = validateMinCheckoutAmount(
          this.currentCartDetails
        )

        if (!isValidCheckoutAmount.valid) {
          var errorDomElement = document.getElementById(
            `${isValidCheckoutAmount.fulfillmentMethodID}-drawer-error`
          )
          if (this.$refs.cartScrollArea && errorDomElement) {
            this.scrollToPosition(
              this.$refs.cartScrollArea,
              errorDomElement.offsetTop -
                this.$refs.cartScrollArea.$el.offsetHeight / 2,
              300
            )
            errorDomElement.classList.add('zoomFocus')

            setTimeout(() => {
              errorDomElement.classList.remove('zoomFocus')
            }, 2000)
          }

          return false
        }
      }

      let slotBookingRequiredType

      if (
        this.checkSlotBookingRequired('sp') &&
        !this.customerSPSlotId &&
        cartHasFulfillment(this.currentCartDetails, 'sp')
      ) {
        slotBookingRequiredType = 'SP'
      }

      if (
        !slotBookingRequiredType &&
        this.checkSlotBookingRequired('scd') &&
        !this.customerSCDSlotId &&
        cartHasFulfillment(this.currentCartDetails, 'scd')
      ) {
        slotBookingRequiredType = 'SCD'
      }

      if (slotBookingRequiredType) {
        this.changeDialogState({
          dialog: 'reserveTimeslot',
          val: true,
          properties: {
            activeTab: slotBookingRequiredType,
            bookType: 'checkout',
          },
        })

        return false
      }

      /* this.$router
        .push({
          name: this.$route.name !== 'Cart' ? 'Cart' : 'Checkout',
        })
        .catch(() => {}) */

      /* if (this.$route.name == 'Cart') {
        this.verifyAgeAndCheckout()
      } else {
        this.$router
          .push({
            name: 'Cart',
          })
          .catch(() => {})
      } */
      this.$router
        .push({
          name: this.$route.name !== 'Cart' ? 'Cart' : 'Checkout',
        })
        .catch(() => {})
    },
    toggleCartPin() {
      this.$store.commit(
        'persistedLocal/SET_PINED_CART_SECTION',
        !this.isCartSectionPined
      )
    },
    verifyAgeAndCheckout() {
      if (
        this.businessConfigurations?.isStoreAgeRestricted &&
        this.businessConfigurations?.enableAgeRestrictedCheckout &&
        this.currentCartDetails?.ageRequired > 0 &&
        !this.isOrderVerified
      ) {
        this.triggerEAIVFlow()
      } else {
        this.$router
          .push({
            name: 'Checkout',
          })
          .catch(() => {})
      }
    },
    triggerEAIVFlow() {
      if (!this.isLoggedIn) {
        // Fetch personalInfo and Prefill inputs
        if (
          this.eaivPersistedVerificationSuccess &&
          this.eaivPersistedSessionId
        ) {
          hideShowFullSpinner(true)
          this.$store
            .dispatch(
              'ageVerification/checkStatus',
              this.eaivPersistedSessionId
            )
            .then((response) => {
              if (response.success && response.data) {
                if (
                  response.data?.isVerified === true &&
                  response.data?.personalInfo
                ) {
                  const personalInfo = response.data.personalInfo
                  let dob = personalInfo.dob
                    ? this.$options.filters.formatDate(
                        personalInfo.dob.split('T')[0],
                        'YYYY-MM-DD'
                      )
                    : ''

                  let reqData = {
                    collectPersonalInfo: false,
                    personalInfo: { ...personalInfo, dob },
                    redirectUri: this.getRedirectUri,
                    verificationDuring: 1,
                    customerID: null,
                  }

                  this.generateSession(reqData, !!!this.isCordova)
                } else {
                  let reqData = {
                    collectPersonalInfo: true,
                    redirectUri: this.getRedirectUri,
                    verificationDuring: 1,
                    customerID: null,
                  }

                  this.generateSession(reqData, !!!this.isCordova)
                }
              }
            })
            .catch(() => hideShowFullSpinner(false))
        } else {
          let reqData = {
            collectPersonalInfo: true,
            redirectUri: this.getRedirectUri,
            verificationDuring: 1,
            customerID: null,
          }

          this.generateSession(reqData, !!!this.isCordova)
        }
      } else {
        let reqData = {
          redirectUri: this.getRedirectUri,
          verificationDuring: 1,
          customerID: null,
        }
        if (this.customer?.isVerifiedOnline) {
          reqData.collectPersonalInfo = false
          reqData.personalInfo = this.personalInfoObj()
        } else {
          reqData.collectPersonalInfo = true
          reqData.preFillInformation = this.personalInfoObj()
        }

        this.generateSession(reqData, !!!this.isCordova)
      }
    },
    openURL(url) {
      if (this.isCordova) {
        window.open(url, '_system')
      } else {
        window.location = url
      }
    },
    generateSession(reqData, isPopup = false) {
      hideShowFullSpinner(true)
      this.$store
        .dispatch('ageVerification/sendPersonalInfo', reqData)
        .then((response) => {
          if (response.success && response.data && response.data?.sessionId) {
            if (isPopup || (!this.isCordova && 'FTXIdentityLite' in window)) {
              let payloadObj = {
                sessionId: response.data.sessionId,
                onSuccess: this.identitySuccessHandler,
                onError: this.identityErrorHandler,
                styleOptions: {
                  height: `calc(100dvh - ${this.getHeaderHeight}px)`,
                  top: `${this.getHeaderHeight}px`,
                },
              }
              this.initEAIVPopup(payloadObj)
            } else if (this.isCordova) {
              this.openURL(
                this.getEAIVExternalURL +
                  `?sessionId=${response.data.sessionId}`
              )
            }
          } else if (!response.success && response.message) {
            this.showError(response.message)
          }
        })
        .finally(() => {
          hideShowFullSpinner(false)
        })
    },
    personalInfoObj() {
      let address = null,
        dob = '',
        personalInfo = {}
      if (this.isLoggedIn) {
        address = this.getAddressByCustomerAddressID(
          this.customer.defaultAddressID
        )

        dob = this.currentUser.dob
          ? this.$options.filters.formatDate(this.currentUser.dob, 'YYYY-MM-DD')
          : ''
        personalInfo = {
          firstName: this.currentUser.firstName || '',
          lastName: this.currentUser.lastName || '',
          dob: dob,
          addressLine1: address?.line1 || '',
          addressLine2: address?.line2 || '',
          city: address?.city || '',
          state: address?.state || '',
          zip: address?.zip || '',
          phone: this.currentUser.phoneNumber || '',
          email: this.currentUser.email || '',
          countryCode: this.currentUser.countryCode || '+1',
        }
      }
      return personalInfo
    },
  },
}
</script>
<style lang="scss">
.sidebarCart {
  // .q-drawer{
  //   width:20%!important;
  //   min-width: 350px;
  // }
  .sidebarHeader {
    padding: 15px 20px;
    font-size: 15px;
    font-family: 'Lato', sans-serif;

    @media (min-width: 1921px) {
      font-size: 20px;
    }

    a {
      color: $tertiary;
      text-decoration: none;

      &.pinned {
        i {
          margin-top: -3px;
          transition: transform 0.3s ease-in-out;
        }

        svg {
          color: #fff;
        }

        &.active {
          i {
            transform: rotate(-45deg);
          }

          svg {
            color: $tertiary;
          }
        }
      }
    }
  }

  .sidebarCartAccordion {
    padding: 0 15px;
  }

  .q-item {
    color: $primaryOnBody;
    color: var(--q-color-primaryOnBody) !important;
    border-bottom: 1px solid #707070;

    svg {
      fill: currentColor;
    }

    .toggleIcon {
      min-width: 40px;
    }

    .toggleTitle {
      font-size: 14px;
      font-weight: bold;

      @media (min-width: 1681px) {
        font-size: 16px;
      }

      @media (min-width: 1921px) {
        font-size: 20px;
      }
    }
  }
}

.cartList {
  .my-card {
    border-bottom: 1px dashed #cccccc;

    &:last-child {
      border-bottom: none;
    }

    .q-item {
      padding: 16px 8px;

      .q-item__section--avatar {
        min-width: 65px;
        padding-right: 8px;
      }

      .cartProductSide {
        align-items: center;
        justify-content: flex-start;
        min-width: 72px;
        padding-left: 8px;

        .cartItemRemove {
          svg {
            color: #e26e6e;
          }

          .q-btn__wrapper {
            min-height: auto;
            padding-top: 0;
          }
        }

        .strike-price {
          text-align: center;
        }
      }

      .product-title {
        font-size: 13px;
        font-weight: bold;
        color: #333b3e;

        @media (min-width: 1921px) {
          font-size: 20px;
        }

        a {
          color: #333b3e;
          text-decoration: none;

          &:hover {
            color: $primaryOnBody;
            color: var(--q-color-primaryOnBody) !important;
            text-decoration: underline;
          }
        }
      }

      .added-cart,
      .product-outstock {
        max-width: 90px;
      }

      .btn-outstock {
        font-size: 10px;
        min-height: 28px;
        line-height: 28px;
      }

      .product-qty {
        font-size: 13px;
        color: #888888;

        .text-strike {
          color: #f6a4a4;
        }

        @media (min-width: 1921px) {
          font-size: 18px;
        }
      }

      .product-price {
        margin-top: 10px;
        font-size: 14px;
        font-weight: bold;
        color: #0f141a;

        @media (min-width: 1921px) {
          font-size: 18px;
        }
      }

      .qtyGroup {
        .q-field__control {
          padding: 0 8px;
          margin: 5px 0;
          height: 36px;

          .q-field__marginal {
            height: 36px;
          }
        }

        input {
          text-align: center;
        }
      }

      .added-cart {
        .btn {
          margin: 0;
          position: relative;

          .q-btn {
            position: absolute;
            left: 7px;
            top: 50%;
            margin-top: -11px;
            z-index: 1;

            &:last-child {
              left: auto;
              right: 7px;
            }

            .q-focus-helper,
            .q-ripple {
              display: none;
            }
          }
        }

        .q-inner-loading {
          top: 5px;
          bottom: 5px;
          -webkit-border-radius: 25px !important;
          border-radius: 25px !important;
        }
      }
    }
  }
}

.cartTotal {
  margin-bottom: 10px;
  background: #fafafa;
  border: 1px solid #eeeeee !important;
  padding: 0 5px;
  min-height: 32px;

  .q-item__section {
    font-size: 10px;
    font-weight: bold;
    color: #888888;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    @media (min-width: 1921px) {
      font-size: 18px;
      padding: 10px;
    }
  }

  .cart-subTotal {
    .strike-price {
      display: inline-block;
      width: auto;
      margin: 0 5px;
    }
  }
}

.cartDrawerHeight {
  .q-scrollarea__content {
    width: 100%;
  }
}

// .cartDrawerHeight {
//   height: calc(100vh - 180px);
//   @media (min-width: 1024px) {
//     height: calc(100vh - 260px);
//   }
// }
// .hasLocationHeader {
//   .cartDrawerHeight {
//     height: calc(100vh - 180px);
//     @media (min-width: 1024px) {
//       height: calc(100vh - 300px);
//     }
//   }
// }
.cartDrawerBtn {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    left: 15px;
    right: 15px;
    top: 0;
    border-top: 2px solid $tertiary;
  }

  p {
    margin: 4px 0 10px;
    padding: 0;
    padding-right: 120px;
    font-size: 15px;
    text-align: left;

    strong {
      position: absolute;
      right: 16px;
    }

    @media (min-width: 1921px) {
      font-size: 18px;
    }
  }

  .q-btn {
    font-size: 14px;

    @media (min-width: 1681px) {
      font-size: 16px;
    }

    @media (min-width: 1921px) {
      font-size: 20px;
    }
  }
}

.cartDrawerTotal {
  .q-card__section--vert {
    padding: 0;
  }

  .q-item {
    border: none;
    position: absolute;
    left: 50%;
    top: -25px;
    margin-left: -28px;

    .q-item__section--side {
      padding-left: 0;
    }

    @include no-hover;
  }

  p {
    margin: 4px 0;
    padding: 0;
    padding-right: 120px;
    font-size: 14px;
    text-align: left;

    strong {
      position: absolute;
      right: 0;
    }
  }
}
</style>
