<template>
  <!-- Sidebar Menu Begin -->
  <q-drawer
    class="sideNav"
    v-model="drawerState"
    overlay
    bordered
    content-class="bg-white"
    :width="width"
    :no-swipe-backdrop="isPWA"
    :no-swipe-open="isPWA"
    :no-swipe-close="isPWA"
  >
    <q-item-label class="sidebarHeader">
      <Logo />
      <!-- All Categories -->
      <q-icon
        class="close cursor-pointer"
        name="close"
        @click="
          changeDialogState({
            dialog: 'categoryDrawer',
            val: false,
          })
        "
      />
    </q-item-label>

    <q-scroll-area
      :thumb-style="scrollAreaThumbStyle"
      :bar-style="scrollAreaBarStyle"
      :visible="scrollAreaVisible"
      style="max-width: 100%"
      class="sideCategory navigationDrawerHeight"
    >
      <!-- <div class="sidebarGroup">
        <q-list dense>
          <q-item-label class="title">Trending</q-item-label>
          <q-item disable>
            <q-item-section>Best Sellers</q-item-section>
          </q-item>
          <q-item disable>
            <q-item-section>New Releases</q-item-section>
          </q-item>
          <q-item disable>
            <q-item-section>Tobacco & Cigars</q-item-section>
          </q-item>
        </q-list>
      </div>
      <q-separator /> -->

      <div class="sidebarGroup" v-if="categories?.length">
        <q-list dense>
          <q-item-label class="title">Shop by Category</q-item-label>
        </q-list>
        <tree-menu
          :key="index"
          :index="index"
          v-for="(tree, index) in getCategories"
          :label="tree.categoryName"
          :category="tree"
          :nodes="tree.childCategory"
          :depth="0"
        />

        <div
          v-if="categories.length > 4"
          class="no-hover q-mt-sm text-body2 text-primary expandable-btn cursor-pointer"
          ref="expandable-category"
          @click="expandCategory('CATEGORY')"
        >
          <q-icon
            :name="showMore.CATEGORY ? 'expand_less' : 'expand_more'"
            size="30px"
          ></q-icon>
          {{ showMore.CATEGORY ? 'See less' : 'See more' }}
        </div>
      </div>

      <q-separator v-if="categories?.length" />
      <div class="sidebarGroup">
        <q-list dense>
          <q-item-label class="title">Programs & Features</q-item-label>
          <q-item :to="{ name: 'DiscountDetail' }">
            <q-item-section>
              <div>
                Discounts & Offers
                <!-- <span class="text-caption text-primary">(Coming soon!)</span> -->
              </div>
              <!-- <q-item-label caption>Coming soon!</q-item-label> -->
            </q-item-section>
            <!-- <q-item-section caption>Coming soon!</q-item-section> -->
          </q-item>
          <q-item
            v-if="storeHasLnsSubscription"
            :to="isLoggedIn && !isCordova ? '/loyalty-program#/' : null"
            clickable
            @click="showLNS"
          >
            <q-item-section>
              <div> Loyalty Program </div>
            </q-item-section>
          </q-item>
        </q-list>
      </div>
      <q-separator />
      <div class="sidebarGroup">
        <q-list dense>
          <q-item-label class="title">Help & Settings</q-item-label>
          <q-item clickable @click="openAccountPage">
            <q-item-section>Your Account</q-item-section>
          </q-item>
          <q-item
            :to="{
              name: 'CustomerService',
            }"
          >
            <q-item-section><div>Customer Support</div></q-item-section>
          </q-item>
          <q-item class="cursor-pointer q-link">
            <q-item-section v-if="isLoggedIn" @click="performLogout">
              Sign out
            </q-item-section>
            <q-item-section v-else @click="signIn"> Sign in </q-item-section>
          </q-item>
        </q-list>
      </div>
      <template v-if="isMobile">
        <q-separator />
        <DisclaimerInfo />
      </template>
      <template v-if="isMobile">
        <q-separator />
        <SectionWeAccept>
          <template v-slot:skeleton>
            <div class="sidebarGroup">
              <q-list dense class="row items-center justify-center">
                <q-item-label class="col-12 title"
                  ><q-skeleton
                    type="text"
                    width="100px"
                    height="30px"
                    class="text-subtitle1"
                /></q-item-label>
                <q-item class="col-12 paymentAcceptIcons">
                  <q-skeleton
                    type="rect"
                    height="32px"
                    width="45px"
                    class="q-mr-xs"
                    v-for="n in 6"
                    :key="n"
                  />
                </q-item>
              </q-list>
            </div>
          </template>
        </SectionWeAccept>
      </template>
      <q-separator />
      <div class="q-pa-sm q-pr-md" v-show="isMobile && !isPWA">
        <InstallationBanner
          :show="showPWAInstallPopupSidebar"
          hide-close
          v-if="isBeforeInstallPromptSupported"
        />
        <InstallationInstructions show hide-close v-else />
      </div>
    </q-scroll-area>
  </q-drawer>
  <!-- Sidebar Menu End -->
</template>
<script>
import { mapGetters } from 'vuex'
import Logo from 'components/header/Logo'

export default {
  data() {
    return {
      sidebarMenuLength: 5,
      showMore: {
        TRENDING: false,
        CATEGORY: false,
      },
    }
  },
  components: {
    TreeMenu: () => import('./TreeMenu'),
    InstallationBanner: () =>
      import('src/components/pwa/InstallationBanner.vue'),
    InstallationInstructions: () =>
      import('src/components/pwa/InstallationInstructions.vue'),
    Logo,
    SectionWeAccept: () => import('components/payment/SectionWeAccept.vue'),
    DisclaimerInfo: () => import('components/mobile-footer/DisclaimerInfo.vue'),
  },
  computed: {
    ...mapGetters('common', [
      'storeHasLnsSubscription',
      'showPWAInstallPopupSidebar',
    ]),
    ...mapGetters('customer', ['customer']),
    drawerState: {
      get() {
        return this.getDialogState('categoryDrawer')
      },
      set(val) {
        if (val != this.drawerState)
          this.changeDialogState({
            dialog: 'categoryDrawer',
            val,
          })
      },
    },
    width: {
      get() {
        return this.$store.state.showcase.drawerWidth
      },
    },
    ...mapGetters('category', ['categories']),
    getCategories() {
      return this.btnShowMore('CATEGORY')
        ? this.categories
        : this.categories.slice(0, this.sidebarMenuLength)
    },
    // getCategories2() {
    //   return this.btnShowMore('TRENDING')
    //     ? this.categories
    //     : this.categories.slice(0, 5)
    // },
    btnShowMore: {
      get() {
        return (key) => this.showMore[key]
      },
      set(val) {
        this.showMore[Object.keys(val)[0]] = Object.values(val)[0]
      },
    },
  },
  methods: {
    expandCategory(key) {
      this.btnShowMore = { [key]: !this.btnShowMore(key) }
    },
    openAccountPage() {
      if (!this.isLoggedIn)
        return this.changeDialogState({
          dialog: 'accountDrawer',
          val: true,
        })

      if (!this.customerExists)
        return this.signUpConfirm().onOk(() => {
          toggleFavoriteCore()
        })
      if (this.$route.path !== '/account') {
        this.$router.push({ name: 'Account' })
      }
    },
    signIn() {
      this.changeDialogState({
        dialog: 'accountDrawer',
        val: true,
      })
    },
    showLNS() {
      if (!this.isLoggedIn) {
        this.changeDialogState({
          dialog: 'accountDrawer',
          val: true,
        })
      } else if (this.isCordova) {
        this.drawerState = false
        this.$store.commit('lnsProgram/SET_SHOW_MOBILE_LOYALTY_PROGRAM', true)
      }
    },
  },
}
</script>
<style lang="scss">
.sideNav {
  .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 9;
    width: 60px;
    height: 60px;
    font-size: 1.5rem;
    @media (min-width: 1681px) {
      width: 70px;
      height: 70px;
    }
  }
  .sidebarHeader {
    padding: 0 20px;
    min-height: 60px;
    line-height: 60px !important;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    background: $primary;
    background: var(--q-color-primary) !important;
    color: $white;
    color: var(--q-color-primaryText);
    /* color: #314154; */
    border-bottom: 1px solid rgba(112, 112, 112, 0.2);
    margin-bottom: 15px;
    @media (min-width: 1024px) {
      font-size: 16px;
      min-height: 70px;
      line-height: 70px !important;
    }
    @media (max-width: 767px) {
      margin: 0;
    }
  }
  .sidebarGroup {
    padding: 20px;
    .title {
      margin: 0;
      padding: 0;
      padding-bottom: 8px;
      font-size: 18px;
      font-weight: bold;
      line-height: normal;
      color: #1f1b1c;
    }
    .q-item {
      padding: 0;
      @include no-hover;
      font-size: 15px;
      color: #1f1b1c;
      &.q-link {
        &:hover {
          color: $primary;
          color: var(--q-color-primary) !important;
        }
      }
    }
    .tree-menu {
      padding: 0;
      a {
        padding-left: 0;
        /* flex: 1 0 0%;
        width: 100%;
        max-width: 100%; */
      }
      /* .arrows {
        flex: 0 0 40px;
        width: 100%;
        max-width: 100%;
      } */
    }
  }
  .q-drawer {
    border: none;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  }
}

.sideCategory {
  .tree-menu {
    padding: 0 15px;
    font-size: 14px;
    @media (min-width: 1681px) {
      font-size: 18px;
    }
    @media (max-width: 1023px) {
      padding: 0 10px;
      font-size: 15px;
    }

    a {
      padding: 12px 10px;
      text-decoration: none;
      /* color: #314154; */
      color: #1f1b1c;
      &:hover {
        color: $primary;
        color: var(--q-color-primary) !important;
      }
      &.router-link-active {
        font-weight: bold;
        color: $primary;
      }
    }
    .current {
      a {
        font-weight: bold;
        /* color: $primary;
        color: var(--q-color-primary) !important; */
      }
    }
    @media (max-width: 767px) {
      /* border-bottom: 1px solid #e2e2e2; */
      > div {
        /* transform: none !important; */
        position: relative;
      }
      .tree-menu {
        /* background: rgba(0, 0, 0, 0.03);
        padding: 0; */
      }
      &:last-child {
        border-bottom: none;
      }
      a {
        /* padding: 18px 10px; */
        padding: 10px;
        padding-right: 60px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .arrows {
        // border-left: 1px solid #e2e2e2;
        position: absolute;
        width: 38px;
        height: 38px;
        right: 0;
        top: 0;
        &:before {
          left: 22px;
        }
      }
    }
  }
}

.sidemenu {
  margin: 0;
  padding: 0;
  left: 0;
  position: relative;
  -webkit-transition: all linear 0.2s;
  -moz-transition: all linear 0.2s;
  -ms-transition: all linear 0.2s;
  -sand-transition: all linear 0.2s;
  -o-transition: all linear 0.2s;
  transition: all linear 0.2s;
  .sidemenu__h {
    min-height: 50px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: row;
    align-items: center;
    .back {
      flex: 0 0 50px;
      height: 39px;
      cursor: pointer;
      &:before {
        content: '';
        width: 12px;
        height: 12px;
        display: block;
        border-bottom: 1px solid #000;
        border-right: 1px solid #000;
        position: relative;
        top: 13px;
        left: 20px;
        transform: rotate(135deg);
      }
      & + .level1 {
        text-align: center;
        padding-right: 50px;
        padding-left: 0;
      }
    }
    .level1 {
      font-size: 20px;
      font-family: 'Poppins', sans-serif;
      flex: 10000 1 0%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  li {
    position: relative;
    &.sub {
      .dropdown {
        left: 100%;
        list-style: none;
        margin: 0;
        padding: 0;
        position: absolute;
        top: 0;
        visibility: hidden;
        width: 100%;
      }
    }
    &.sub.current {
      .dropdown {
        visibility: visible;
      }
    }
    &.current {
      position: initial;
    }
  }
  .relative-position {
    display: block;
  }
  .level1 {
    margin: 0;
    padding: 12px 15px;
    padding-right: 50px;
    color: $textdark;
    font-size: 18px;
    font-family: 'Lato', sans-serif;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .sub {
    .arrow {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 50px;
      display: block;
      cursor: pointer;
      &:before {
        content: '';
        width: 12px;
        height: 12px;
        display: block;
        border-bottom: 1px solid #000;
        border-right: 1px solid #000;
        position: relative;
        top: 50%;
        left: 15px;
        transform: translateY(-50%) rotate(-45deg);
      }
    }
  }
  .dropdown {
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      .level2 {
        margin: 0;
        padding: 12px 15px;
        padding-right: 50px;
        color: #314154;
        font-size: 18px;
        font-family: 'Lato', sans-serif;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
.navigationDrawerHeight {
  height: calc(100dvh - 76px);
  @media (min-width: 1024px) {
    height: calc(100dvh - 84px);
  }
}
.hasLocationHeader {
  .navigationDrawerHeight {
    height: calc(100dvh - 61px);
    @media (min-width: 1024px) {
      height: calc(100dvh - 215px);
    }
  }
}
</style>
