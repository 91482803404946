<template>
  <q-input
    class="col h-search-input"
    borderless
    dense
    bg-color="white"
    color="dark"
    bottom-slots
    v-model="searchText"
    @click="
      () => {
        if (this.isMobile) {
          this.$refs.searchBar.blur()
          this.changeDialogState({
            dialog: 'MobileSearchDialog',
            val: true,
          })
        } else {
          this.focus = true
        }
      }
    "
    @blur="
      () => {
        this.focus = false
        this.searchingProduct = false
      }
    "
    ref="searchBar"
    placeholder="What are you looking for?"
    @keyup="initiateSearch"
    @focus="initiateSearch"
    autocomplete="off"
    maxlength="40"
  >
    <div v-if="!isMobile" class="h-search-dropdown" v-show="focus">
      <div
        class="h-search-1"
        v-show="searchText && searchText.trim().length < 3"
      >
        <div class="label" v-show="Object.keys(trendingProducts).length">
          Trending
        </div>
        <q-scroll-area
          :thumb-style="{ ...scrollAreaThumbStyle, zIndex: 1000 }"
          :bar-style="{ ...scrollAreaBarStyle, zIndex: 1000 }"
          :visible="scrollAreaVisible"
          :style="{
            height:
              Object.keys(trendingProducts).length * 54 > 162
                ? '162px'
                : `${Object.keys(trendingProducts).length * 54}px`,
          }"
          v-if="Object.keys(trendingProducts).length"
        >
          <q-list padding v-show="Object.keys(trendingProducts).length">
            <q-item
              v-for="(product, productId) in trendingProducts"
              :key="productId"
              clickable
              @click="focus = false"
            >
              <q-item-section avatar>
                <q-avatar rounded>
                  <q-img :src="product.image | defaultProductImage" />
                </q-avatar>
              </q-item-section>
              <q-item-section>
                <router-link
                  :to="{
                    name: 'Detail',
                    params: { seoName: product.seoName },
                  }"
                  v-if="product.seoName"
                >
                  {{ product.productName }}
                </router-link>
                in
                <span class="text-primary">
                  <router-link
                    v-for="(category, index) in product.categories"
                    :key="`${category.categoryID}-${index}`"
                    :to="{
                      name: 'Category',
                      params: { seoName: category.categorySeoName },
                    }"
                  >
                    {{
                      $options.filters.titleCase(category.categoryName +
                      (product.categories.length - 1 != index ? ', ' : ''))
                    }}
                  </router-link>
                </span>
              </q-item-section>
            </q-item>
          </q-list>
        </q-scroll-area>

        <div class="label" v-show="frequentSearches.length">
          Frequent Searches
        </div>
        <q-scroll-area
          :thumb-style="{ ...scrollAreaThumbStyle, zIndex: 1000 }"
          :bar-style="{ ...scrollAreaBarStyle, zIndex: 1000 }"
          :visible="scrollAreaVisible"
          :style="{
            height:
              frequentSearches.length * 42 > 126
                ? '126px'
                : `${frequentSearches.length * 42}px`,
          }"
          v-if="frequentSearches.length"
        >
          <q-list padding>
            <router-link
              v-for="(item, index) in frequentSearches"
              :key="`${item}-${index}`"
              :to="{
                name: 'Search',
                query: { q: item },
              }"
              class="no-underline"
              @click.native="focus = false"
            >
              <q-item class="items-center">
                <q-item-section avatar>
                  <q-icon color="dark" name="search" />
                </q-item-section>
                <q-item-section>{{ item }}</q-item-section>
              </q-item>
            </router-link>
          </q-list>
        </q-scroll-area>
      </div>
      <div
        class="h-search-2"
        v-show="
          searchText && searchText.trim().length >= 3 && !searchingProduct
        "
        v-if="
          productsByKeyword &&
          productsByKeyword.items &&
          productsByKeyword.items.length
        "
        :class="{
          'one-record': productsByKeyword.items.length == 1,
          'two-record': productsByKeyword.items.length == 2,
        }"
      >
        <q-scroll-area
          :thumb-style="{ ...scrollAreaThumbStyle, zIndex: 1000 }"
          :bar-style="{ ...scrollAreaBarStyle, zIndex: 1000 }"
          :visible="scrollAreaVisible"
          :style="{
            height:
              productsByKeyword &&
              productsByKeyword.items &&
              productsByKeyword.items.length * 55 > 275
                ? '290px'
                : `${productsByKeyword.items.length * 55 + 15}px`,
          }"
        >
          <q-list padding>
            <q-item
              v-for="product in productsByKeyword.items"
              :key="product.productID"
            >
              <q-item-section avatar>
                <router-link
                  class="no-underline"
                  :to="{
                    name: 'Detail',
                    params: { seoName: product.seoName },
                  }"
                  style="width: 50px; padding: 5px"
                >
                  <q-avatar rounded>
                    <q-img
                      :src="
                        product && product.images && product.images.length
                          ? product.images[0].imageUrl
                          : $options.filters.defaultProductImage('')
                      "
                      contain
                      style="width: 40px; height: 40px"
                    />
                  </q-avatar>
                </router-link>
              </q-item-section>
              <q-item-section>
                <q-item-label>
                  <router-link
                    class="no-underline ellipsis"
                    :to="{
                      name: 'Detail',
                      params: { seoName: product.seoName },
                    }"
                    @click.native="focus = false"
                  >
                    {{ product.title }}
                    <q-tooltip
                      v-if="!isMobile"
                      max-width="180px"
                      content-class="bg-dark"
                    >
                      {{ product.title }}
                    </q-tooltip>
                  </router-link>
                </q-item-label>

                <q-item-label caption lines="2">
                  1 x
                  <strong class="text-primaryOnBody q-mr-sm">
                    {{ product.price | currency }}
                  </strong>
                  <span class="text-strike" v-if="false">
                    {{ product.compareAtPrice | currency }}
                  </span>
                </q-item-label>
              </q-item-section>
              <q-item-section class="h-search-cart" side top v-if="!isMobile">
                <AddToCartBtn
                  :productFulFillments="
                    product.fulFillments
                      ? product.fulFillments
                      : product.productFulfillments
                  "
                  :productId="product.productID"
                  :onDeliveryModeChnaged="onDeliveryModeChnaged"
                  :isinstock="product.isinstock"
                />
              </q-item-section>
            </q-item>
          </q-list>
        </q-scroll-area>

        <router-link
          class="more-products"
          :to="{
            name: 'Search',
            query: { q: searchText },
          }"
          @click.native="focus = false"
        >
          See All Results
        </router-link>
      </div>
      <div
        class="h-search-2"
        v-show="searchText && searchText.trim().length >= 3"
      >
        <div v-show="searchingProduct">
          <q-item v-for="n in 3" :key="n">
            <q-item-section avatar top>
              <q-skeleton width="50px" height="50px" class="q-mx-auto" />
            </q-item-section>
            <q-item-section top>
              <q-skeleton type="rect" width="70%" class="q-mb-sm" />
              <q-skeleton type="text" width="20%" class="q-mb-sm" />
            </q-item-section>
            <q-item-section side top v-if="!isMobile">
              <q-skeleton
                type="rect"
                width="184px"
                height="38px"
                class="q-mb-sm"
              />
            </q-item-section>
          </q-item>
        </div>
        <EmptyComponent
          :imgWidth="160"
          v-show="
            !searchingProduct &&
            productsByKeyword &&
            productsByKeyword.items &&
            productsByKeyword.items.length == 0
          "
          image="images/empty-search.png"
        >
          <p v-if="currentLocationId">
            No product available matching &nbsp;
            <strong>
              {{ searchText }}
            </strong>
          </p>
          <p v-else>
            Please select the location before searching the product
          </p>
        </EmptyComponent>
        <!-- <q-item
          v-show="!searchingProduct && productsByKeyword.items.length == 0"
        >
          No product available matching &nbsp;<strong>{{ searchText }}</strong>
        </q-item> -->
      </div>
    </div>

    <template v-slot:append>
      <q-btn
        dense
        flat
        :ripple="false"
        @click="clearInput"
        icon="close"
        size=".5em"
        v-show="searchText && searchText.trim()"
      />
      <q-btn dense flat :ripple="false" @click="searchClicked">
        <SvgIcon
          className="q-ml-sm q-mr-sm"
          color="grey"
          size="1rem"
          icon="simple-search"
        />
      </q-btn>
    </template>
    <template v-slot:prepend v-if="isMobile">
      <q-btn dense flat :ripple="false">
        <SvgIcon color="grey" size="1rem" icon="back-arrow-line" />
      </q-btn>
    </template>
  </q-input>
</template>

<script>
import { mapGetters } from 'vuex';
import AddToCartBtn from '../AddToCartBtn';

export default {
  components: { AddToCartBtn },
  data() {
    return {
      searchingProduct: false,
      searchValue: '',
      timer: null,
      localSearchText: null,
      focus: false,
      productsByKeyword: {
        items: [],
      },
    }
  },
  watch: {
    $route: function (route) {
      this.localSearchText = null
      this.$store.commit('category/SET_SELECTED_SEARCH_CATEGORY')
    },
    'searchCategory.categoryID': function (newVal, oldVal) {
      if (this.searchText && this.searchText.trim().length >= 3) {
        this.getProductByKeyword(this.searchText)
      }
    },
  },
  computed: {
    ...mapGetters('common', [
      'frequentSearches',
      'trendingProducts',
      'currentDeliveryModes',
    ]),
    ...mapGetters('category', ['searchCategory']),
    searchText: {
      get() {
        let route = this.$route
        if (this.localSearchText !== null) {
          return this.localSearchText
        } else if (route.name === 'Search' && route.query?.q) {
          let searchQuery = route.query.q
          if (searchQuery.constructor === Array) {
            let si = route.query.si

            if (typeof searchQuery[parseInt(si)] != 'undefined')
              return searchQuery[parseInt(si)]

            return searchQuery[0]
          } else if (
            searchQuery.constructor === String &&
            searchQuery.trim().length
          ) {
            return searchQuery.trim()
          }
        }
        return ''
      },
      set(val) {
        this.localSearchText = val
      },
    },
  },
  methods: {
    clearInput() {
      this.searchText = ''
      this.$refs.searchBar.blur()
    },
    onDeliveryModeChnaged() {
      if (this.$refs.searchBar) this.$refs.searchBar.focus()
    },
    initiateSearch(event) {
      if (event.keyCode == 27 && this.focus) event.target.blur()
      if (!this.currentLocationId) return

      if (
        event.keyCode == 13 &&
        this.focus &&
        this.searchText.trim() &&
        !(
          this.$route.name == 'Search' && this.$route.query.q == this.searchText
        )
      ) {
        event.target.blur()
        this.$router
          .push({ name: 'Search', query: { q: this.searchText } })
          .catch(() => {})
      }
      let value = event.target.value.trim()
      if (value.length >= 3) {
        if (this.searchValue === value) return

        this.searchingProduct = true

        this.searchValue = value
        if (this.timer) {
          clearTimeout(this.timer)
          this.timer = null
        }

        this.timer = setTimeout(() => {
          this.getProductByKeyword(this.searchValue)
        }, 400)
      }
    },
    getProductByKeyword(value) {
      if (!this.currentLocationId) return

      let params = {
        searchKeyword: value,
        PageSize: 20,
        isFrequentSearch: true,
        categoryID: this.searchCategory.categoryID,
        ...this.currentDeliveryModes,
      }

      this.searchingProduct = true
      this.$store
        .dispatch('product/getByKeyword', params)
        .then((data) => {
          this.productsByKeyword = data
        })
        .finally(() => {
          this.searchingProduct = false
        })
    },
    searchClicked() {
      if (this.isMobile) {
        this.changeDialogState({
          dialog: 'MobileSearchDialog',
          val: true,
        })
      }

      this.focus = false

      if (this.$refs.searchBar) this.$refs.searchBar.blur()

      if (
        this.searchText &&
        !(
          this.$route.name == 'Search' && this.$route.query.q == this.searchText
        )
      ) {
        this.$router
          .push({ name: 'Search', query: { q: this.searchText } })
          .catch(() => {})
      }
    },
  },
}
</script>
<style lang="scss">
.h-search {
  position: relative;
  .q-field__prepend {
    display: none;
  }
  .search_bar {
    > .row {
      border: 1px solid #00000020;
      border-radius: 6px;
    }
  }
  @media (max-width: 767px) {
    &.open {
      .h-search-dropdown {
        background: #f7f7f7;
        box-shadow: none;
        padding: 15px 10px;
        top: calc(100% + 1px);
      }
      .q-field__prepend {
        display: flex;
      }
      .search_bar {
        position: fixed;
        background: #f7f7f7;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 99;
        .row {
          margin: 0;
        }
      }
      .q-scrollarea {
        height: calc(100vh - 115px) !important;
      }
      .more-products {
        height: 40px;
        border-radius: 0;
      }
      .h-search--all,
      .h-search--list {
        display: none;
      }
      .q-field__control,
      .q-field__marginal {
        height: 60px;
      }
      .q-field__control {
        padding: 0 15px;
        border-bottom: 1px solid #e8e8e8;
      }
    }
  }
  .q-field--outlined.q-field--focused .q-field__control:after {
    border: #fff;
  }
  .q-field__control-container {
    position: initial;
  }
  .h-search-dropdown {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    background: #fff;
    border-radius: 0 0 5px 5px;
    z-index: 9;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    // .h-search-1,
    // .h-search-2 {
    //   // opacity: 0;
    //   // visibility: hidden;
    //   // display: none;
    // }
    .label {
      background: #eeeeee;
      color: #000;
      font-size: 12px;
      font-weight: bold;
      display: block;
      padding: 10px 15px;
    }
  }
  .q-field__native {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 13px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    @media (min-width: 1921px) {
      font-size: 20px;
    }
  }
  .q-field--focused {
    .h-search-dropdown {
      opacity: 1;
      visibility: visible;
    }
    /*.h-search-1 {
      opacity: 1;
      visibility: visible;
      display: block;
    }*/
  }
  .q-field--float {
    .h-search-dropdown {
      opacity: 1;
      visibility: visible;
    }
    // .h-search-2 {
    //   opacity: 1;
    //   visibility: visible;
    //   display: block;
    // }
  }
  .h-search--all {
    border-radius: 5px 0 0 5px;
  }
  .h-search--list {
    border-radius: 0 5px 5px 0;
    background: #fff;
    .q-focus-helper {
      opacity: 0 !important;
    }
    .q-btn__content:before {
      content: '';
      width: 1px;
      height: 15px;
      display: block;
      position: absolute;
      left: 3px;
      top: 50%;
      background: #cccccc;
      transform: translateY(-50%);
    }
  }
  .h-search-cart {
    width: 160px;
    .q-field__prepend {
      display: flex;
    }
    .add-cart {
      .icon {
        padding: 6px 8px;
      }
      &.addDelivery {
        .btn {
          /* flex: 10000 0 0%; */
          padding: 6px 8px;
        }
      }
    }
    .added-cart {
      .icon {
        padding: 6px 8px;
      }
      .q-field__control,
      .q-field__marginal {
        height: 30px;
      }
      .btn {
        .q-btn {
          flex: 0 0 30px;
        }
      }
      .q-field__native {
        padding-left: 0;
        padding-right: 0;
      }
    }
    .btn-outstock {
      min-height: 31px;
      line-height: 31px;
    }
  }
}
.h-quick-shop {
  width: 280px;
  padding: 10px 0;
  font-size: 12px;
  h4 {
    margin: 0;
    padding: 0;
    font-size: 12px;
    line-height: normal;
    color: $tertiary;
    position: relative;
    .close {
      position: absolute;
      right: 0;
      top: 0;
      color: $primary;
      color: var(--q-color-primary) !important;
      font-size: 16px;
    }
  }
  textarea {
    padding: 0 !important;
    line-height: 2.16rem !important;
    background: url(/images/textarea-vline.jpg) left top repeat;
  }
  @media (max-width: 1024px) {
    width: 100%;
    // padding: 16px;
    h4 {
      font-size: 17px;
      line-height: 24px;
    }
  }
}
.h-search-1 {
  .q-item {
    min-height: 42px;
  }
  .q-item__section--avatar {
    min-width: 30px;
    .q-icon {
      font-size: 21px;
    }
  }
  .q-item__section--main {
    display: block;
    font-size: 12px;
    @media (min-width: 1681px) {
      font-size: 16px;
    }
    .text-primary {
      a {
        color: $primary;
        color: var(--q-color-primary) !important;
        text-decoration: underline;
      }
    }
  }
  .q-list--padding {
    padding: 0;
  }
  a {
    color: #333b3e;
    text-decoration: none;
    &:hover {
      color: $primary;
      color: var(--q-color-primary) !important;
    }
  }
}
.h-search-2 {
  a {
    color: #333b3e;
    font-size: 12px;
    text-decoration: none;
    display: block;
    &:hover {
      color: $primary;
      color: var(--q-color-primary) !important;
      text-decoration: underline;
    }
  }
  .q-list--padding {
    padding: 10px 0 15px !important;
  }
  .q-avatar {
    font-size: 40px;
  }
  .more-products {
    display: block;
    padding: 10px;
    font-size: 13px;
    font-weight: 500;
    background: rgb(242, 242, 242);
    background: -moz-linear-gradient(
      top,
      rgba(242, 242, 242, 1) 0%,
      rgba(247, 247, 247, 1) 40%,
      rgba(229, 229, 229, 1) 100%
    );
    background: -webkit-linear-gradient(
      top,
      rgba(242, 242, 242, 1) 0%,
      rgba(247, 247, 247, 1) 40%,
      rgba(229, 229, 229, 1) 100%
    );
    background: linear-gradient(
      to bottom,
      rgba(242, 242, 242, 1) 0%,
      rgba(247, 247, 247, 1) 40%,
      rgba(229, 229, 229, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f2f2f2', endColorstr='#e5e5e5',GradientType=0 );

    border-top: 1px solid rgba(112, 112, 112, 0.1);
    text-align: center;
    box-shadow: 0px 0px 10px rgba(112, 112, 112, 0.2);
    border-radius: 0 0 5px 5px;
    &:hover {
      color: $primary;
      color: var(--q-color-primary) !important;
      text-decoration: none;
    }
  }
}
</style>
