<template>
  <div>
    <!-- <q-no-ssr>
      <Alert
        class="q-mt-lg q-ml-lg q-mr-lg"
        dismissible
        :variant="alertData.variant"
        :show="alertData.showCountDown"
        @dismissed="dismissed"
        @dismiss-count-down="countDownChanged"
        v-if="!isMobile"
      >
        <span v-html="alertData.message" />
      </Alert>
    </q-no-ssr> -->

    <keep-alive :include="['PageHome', 'PageSearchLocation', 'PageLocation']">
      <router-view :key="$route.fullPath" />
    </keep-alive>

    <q-page-scroller
      position="bottom-right"
      :scroll-offset="300"
      :offset="[18, 18]"
      v-if="!isMobile"
    >
      <q-btn color="primary" fab icon="keyboard_arrow_up" />
    </q-page-scroller>
    <q-no-ssr>
      <Footer ref="footer" v-if="!isMobile" />
    </q-no-ssr>
  </div>
</template>

<script>
import Footer from 'components/Footer'
import { mapGetters } from 'vuex'

export default {
  name: 'PageContainer',
  components: { Footer },
  computed: {
    ...mapGetters('common', ['alertData']),
  },

  // ToDo: Un used Code
  // methods: {
  //   dismissed() {
  //     this.$store.commit('common/COUNT_DOWN_CHANGE', 0)
  //   },
  //   countDownChanged(dismissCountDown) {
  //     this.$store.commit('common/COUNT_DOWN_CHANGE', dismissCountDown)
  //   },
  // },
}
</script>
