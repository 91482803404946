<template>
  <q-card
    :class="['notification-lists', { 'show-notification': notificationDrawer }]"
  >
    <q-card-section
      class="bg-primary text-white flex items-center q-py-sm notification-header"
    >
      <span class="text-weight-bold"> Notification</span>
      <q-btn
        dense
        flat
        @click="$emit('openNotificationDrawer')"
        class="q-ml-auto"
      >
        <q-icon name="close" />
      </q-btn>
    </q-card-section>
    <q-card-section class="q-pr-xs notification-main">
      <q-scroll-area
        :thumb-style="scrollAreaThumbStyle"
        :bar-style="scrollAreaBarStyle"
        :visible="scrollAreaVisible"
        class="notification-scroll"
        :style="
          isMobile
            ? 'height:calc(100vh - 122px); padding-right:15px;'
            : 'height:400px; padding-right:15px;'
        "
      >
        <div
          v-if="loading"
          class="flex items-center justify-center"
          :style="isMobile ? 'height:calc(100vh - 122px);' : 'height:400px;'"
        >
          <q-spinner color="primary" size="3em" :thickness="2" />
        </div>
        <template v-else>
          <template v-if="notifications.length">
            <div
              v-for="notification in notifications"
              :key="notification.notificationID"
            >
              <NotificationCard :notification="notification" /> </div
          ></template>
          <div
            v-else
            class="notification-placeholder"
            :style="isMobile ? 'height:calc(100vh - 122px);' : 'height:400px; '"
          >
            <img
              src="images/empty-notification.png"
              width="100"
              height="100"
              alt=""
            />

            <span class="q-mt-md text-grey-5">No Notifications</span>
          </div>
        </template>
      </q-scroll-area>
    </q-card-section>
    <q-card-section class="bg-primary text-right q-py-sm notification-footer">
      <q-btn
        class="notification-clear-btn"
        no-caps
        :disable="notificationcount == 0"
        @click="clearAllNotification"
        >Clear All
      </q-btn>
    </q-card-section>
  </q-card>
</template>

<script>
import NotificationCard from './NotificationCard.vue'
import { hideShowFullSpinner } from 'src/utils'
import { mapGetters } from 'vuex'
export default {
  name: 'NotificationList',
  props: {
    notificationDrawer: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters('notification', [
      'notifications',
      'notificationcount',
      'loading',
    ]),
  },

  components: {
    NotificationCard,
  },
  methods: {
    clearAllNotification() {
      this.$store.dispatch('notification/removeAllNotification')
    },
  },
}
</script>

<style lang="scss">
.notification-clear-btn {
  background-color: var(--q-color-secondary);
  color: #fff;
}
.notification-placeholder {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.notification-scroll {
  max-height: calc(100vh - 300px);
  .scroll {
    .absolute.full-width {
      min-height: 100%;
    }
  }
  @media (max-width: 1023px) {
    max-height: calc(100dvh - 120px);
  }
}
</style>
