<template>
  <q-layout
    :view="!isMobile ? 'lHh Lpr lfr' : 'lHh lpr lFf'"
    :class="[
      scrollClass,
      showLocationHeader ? 'hasLocationHeader' : '',
      showAgeVerificationBtn ? 'show-age-verification' : '',
    ]"
  >
    <Header ref="headerBox" />
    <!-- Footer  && isMobile -->
    <MobileFooter v-if="$route.name == 'Home' && isMobile" />
    <!-- Footer -->

    <!-- Page Container Begin -->
    <q-page-container
      :class="{
        pinCart:
          isCartSectionPined && cartDrawerPinedPage.includes(currentRouteName),
      }"
    >
      <SubHeader v-if="$route.name == 'Home' && !isMobile" />
      <!-- <q-btn
        unelevated
        color="primary"
        @click="
          changeDialogState({
            dialog: 'DisclaimerDialog',
            val: true,
          })
        "
        class="q-ma-md"
      >
        Disclaimer Dialog
      </q-btn> -->
      <q-scroll-area
        :thumb-style="{ ...scrollAreaThumbStyle, zIndex: 2000 }"
        :bar-style="{ ...scrollAreaBarStyle, zIndex: 2000 }"
        :visible="scrollAreaVisible"
        class="container-scroll-area"
        :style="{
          height:
            $route.name === 'Home' && !isMobile
              ? `calc(100vh - (${headerHight}px + 50px))`
              : `calc(100vh - (${headerHight}px))`,
        }"
        ref="mainScrollArea"
        v-if="!isMobile"
        @scroll="$root.$emit('main-container-scroll')"
      >
        <PageContainer />
      </q-scroll-area>
      <div v-else>
        <PageContainer />
      </div>
    </q-page-container>

    <q-no-ssr>
      <template v-if="currentLocationId">
        <template v-if="!isLoggedIn || (isLoggedIn && customerExists)">
          <ReserveTimeSlot
            v-if="isSlotBookingRequired"
            :location="currentLocation"
          />
          <AddAddressDialog />
          <CMSDialog />
        </template>

        <CategoryDrawer />
        <DeliveryInfoModal />
        <QuickProductDetailDialog />
        <MobileSearchDialog
          :currentLocationId="currentLocationId"
          v-if="isMobile"
        />
        <ChangeLocationConfirm />
        <LNSCouponsDialog />
        <UpdateProductQuantityDialog />
      </template>

      <OffersModal />
      <WishlistDialog />
      <AccountDrawer />
    </q-no-ssr>

    <CartDrawer v-if="!isMobile" />
    <!-- Page Container End -->
    <!-- !isMobile && -->
    <q-no-ssr>
      <div
        class="mainLayoutBackdrop"
        v-show="
          showcase.dialog.categoryDrawer ||
          showcase.dialog.accountDrawer ||
          (showcase.dialog.cartDrawer &&
            !(
              isCartSectionPined &&
              cartDrawerPinedPage.includes(currentRouteName)
            ))
        "
        @click="
          changeDialogState({
            dialog: showcase.dialog.categoryDrawer
              ? 'categoryDrawer'
              : showcase.dialog.cartDrawer
              ? 'cartDrawer'
              : 'accountDrawer',
            val: false,
          })
        "
      >
      </div>

      <AppBarcode v-if="!isMobile" />

      <template v-if="businessConfigurations.isStoreAgeRestricted">
        <PersonalInfoDialog />
        <EAIVSpinner />
        <AgeVerificationBtn v-show="showAgeVerificationBtn" />
        <MismatchPersonalInfo />
        <AskForSignup />
      </template>

      <VerificationFailDialog />
      <SetDateTimeDialog />
    </q-no-ssr>
  </q-layout>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import Header from 'components/header'
import PageContainer from 'components/PageContainer'
import CategoryDrawer from 'components/CategoryDrawer'
import AccountDrawer from 'components/AccountDrawer'
import CartDrawer from 'components/CartDrawer'
import OffersModal from 'components/offer-and-deals/offersModal.vue'
import MobileFooter from 'components/mobile-footer'
import SubHeader from 'components/header/SubHeader'
import EAIVSpinner from 'components/dialog/eaiv/EAIVSpinner'

import {
  splashscreenShowHide,
  reloadPage,
  hideShowFullSpinner,
} from 'src/utils'
import { ageRestrictedBrowsingPages, cartDrawerPinedPage } from 'src/constants'

let drawerGap = 40

export default {
  name: 'MainLayout',
  // async preFetch({ store, currentRoute, previousRoute }) {
  /* let promises = [],
      currentLocationId = store.getters['persisted/currentLocationId']
    promises.push(store.dispatch('common/checkDeliveryAddressForLocations'))
    if (store.getters['auth/customerExists'] && currentLocationId) {
      if (currentRoute.name == 'Cart' && currentRoute.name !== 'Checkout')
        promises.push(
          store.dispatch('cart/getCartDetail', {
            showFullLoader: true,
          })
        )
    }
    if (process.env.SERVER) return Promise.all(promises) */
  // if (
  //   !store.getters['persisted/isPreferredLocation'] &&
  //   !store.getters['persisted/isProceedLocation'] &&
  //   currentRoute.name == 'Home'
  // )
  // store.commit('showcase/updateLocationHeader', true)
  // promises.push(store.dispatch('common/checkDeliveryAddressForLocations'))
  // if (currentLocationId)
  //   promises.push(
  //     store.dispatch('category/getCategories', { currentLocationId })
  //   )
  /* if (store.getters['auth/isLoggedIn'])
      promises.push(store.dispatch('wishlist/getAllWishlist')) */
  /* if (store.getters['auth/customerExists'] && currentLocationId) {
      if (currentRoute.name == 'Cart' && currentRoute.name !== 'Checkout')
        promises.push(store.dispatch('cart/getCartDetail'))
    }
    if (process.env.SERVER) return Promise.all(promises) */
  // },
  computed: {
    ...mapState(['showcase']),
    ...mapGetters('geolocation', ['latitude', 'longitude']),
    ...mapGetters('persisted', [
      'isProceedLocation',
      // 'isPreferredLocation',
      'isBetaAccessCodeValidated',

      'isBetaModeActive',
      'eaivPersistedSessionId',
      'eaivPersistedVerificationSuccess',
      'isTestModeActive',
      'isOfflineModeActive',
      'locationOrShippingZip',
    ]),
    ...mapGetters('persistedLocal', [
      'isCartSectionPined',
      'showAppDownloadNotification',
    ]),
    ...mapGetters('common', [
      'storeConfigurationLoaded',
      'forceUserToEnterZipCode',
      'isSlotBookingRequired',
      'allBusinessFulfillments',
    ]),
    ...mapGetters('customer', ['customer']),
    ...mapGetters('ageVerification', [
      'isVerificationFail',
      'showVeirificationPopup',
      'skipBrowsingAgeRestrictedProd',
    ]),
    currentRouteName() {
      return this.$route.name
    },
    showLocationHeader: {
      get() {
        return this.$store.state.showcase.showLocationHeader
        // && !this.isProceedLocation
      },
    },
  },
  components: {
    Header,
    PageContainer,
    CategoryDrawer,
    AccountDrawer,
    CartDrawer,
    OffersModal,
    MobileFooter,
    SubHeader,
    //lazy load component
    MobileSearchDialog: () => import('components/header/MobileSearchDialog'),
    ReserveTimeSlot: () => import('components/reserve-time-slots'),
    DeliveryInfoModal: () => import('components/DeliveryInfoModal'),
    QuickProductDetailDialog: () =>
      import('components/QuickProductDetailDialog'),
    AddAddressDialog: () => import('components/checkout/AddAddressDialog'),
    ChangeLocationConfirm: () => import('components/ChangeLocationConfirm'),
    CMSDialog: () => import('components/detail/CMSDialog'),
    LNSCouponsDialog: () => import('components/LNSCouponsDialog'),
    AppBarcode: () => import('components/AppBarcode'),
    UpdateProductQuantityDialog: () =>
      import('components/checkout/UpdateProductQuantityDialog'),
    WishlistDialog: () => import('components/dialog/WishlistDialog.vue'),
    PersonalInfoDialog: () =>
      import('components/dialog/eaiv/PersonalInfoDialog.vue'),
    VerificationFailDialog: () =>
      import('components/dialog/eaiv/VerificationFailDialog.vue'),
    EAIVSpinner,
    AgeVerificationBtn: () =>
      import('components/dialog/eaiv/AgeVerificationBtn.vue'),
    MismatchPersonalInfo: () =>
      import('components/dialog/eaiv/MismatchPersonalInfo.vue'),
    AskForSignup: () => import('components/dialog/eaiv/AskForSignup.vue'),
    SetDateTimeDialog: () => import('components/common/SetDateTimeDialog.vue'),
  },
  data() {
    return {
      cartDrawerPinedPage,
      scrollClass: 'scrollUp',
      lastScroll: 0,
      headerHight: 0,
      availableStatus: ['TrackOrder', 'OrderDetail', 'Reviews'],
      announcement: false,
      disclaimer: false,
    }
  },
  created() {
    this.$root.$on('eaivVerificationSuccess', this.onEaivSuccess)
    if (this.isCartSectionPined && this.isMobile)
      this.$store.commit('persistedLocal/SET_PINED_CART_SECTION', false)

    if (this.$root) {
      this.$root.$on('hide-header', () => {
        if (this.$refs.headerBox && this.$refs.headerBox.$el) {
          this.headerHight = this.$refs.headerBox.$el.clientHeight
        }
      })

      this.$root.$on('scroll-to-position', (position = 0, delay = 100) => {
        this.scrollToPosition(this.$refs.mainScrollArea, position, delay)
      })
    }
  },
  beforeMount() {
    splashscreenShowHide(false)

    let width = this.$q.screen.width
    if (width < 600)
      this.$store.commit('showcase/updateDrawerWidth', width - drawerGap)

    // this.$store.dispatch('geolocation/queryPermission')

    // if (!this.isPreferredLocation && !this.forceUserToEnterZipCode) {
    //   if (!this.isProceedLocation && this.$route.name == 'Home') {
    //     this.$store
    //       .dispatch('geolocation/samplePosition')
    //       .then(async (data) => {
    //         if (data && data.coords) {
    //           if (
    //             this.latitude == data.coords.latitude &&
    //             this.longitude == data.coords.longitude
    //           )
    //             return

    //           this.$store.commit('geolocation/SET_POSITION', data)

    //           let isLocationChanged = await this.$store.dispatch(
    //             'persisted/fetchLocation',
    //             {
    //               IncludeConfiguration: true,
    //               IsDefaultOnly: true,
    //               geoLocation: `${data.coords.latitude},${data.coords.longitude}`,
    //             }
    //           )

    //           await this.$store.commit('persisted/SET_SERVICE_LOCATION')

    //           if (isLocationChanged) window.location = '/'
    //         }
    //       })
    //   }
    // }

    // setTimeout(() => {
    //   this.$store.commit('showcase/updateLocationHeader', false)
    // }, 5000)

    if (this.currentLocationId) {
      this.$store.dispatch('category/getCategories', {
        currentLocationId: this.currentLocationId,
      })
      this.$store.dispatch(
        'category/getTrendingCategory',
        this.currentLocationId
      )
      this.$store.dispatch('product/getTrendingProducts')
    }
  },
  mounted() {
    // prefetch code
    this.$store.dispatch('common/checkDeliveryAddressForLocations')

    // if (
    //   this.customerExists &&
    //   this.currentLocationId &&
    //   this.$route.name == 'Cart'
    // )
    //   this.$store.dispatch('cart/getCartDetail')

    // document.getElementById('mainPreLoader')?.remove()
    if (!this.customerExists && this.isLoggedIn) this.signUpConfirm()

    hideShowFullSpinner(false)
    if (this.$refs.headerBox?.$el)
      this.headerHight = this.$refs.headerBox.$el.clientHeight

    if (this.$refs.footer?.$el)
      this.$store.commit(
        'showcase/updateFooterHeight',
        this.$refs.footer.$el.clientHeight
      )

    if (this.isLoggedIn && this.customerExists) {
      if (
        this.currentLocationId &&
        this.$route.name != 'Checkout' &&
        this.$route.name != 'Cart'
      )
        this.$store.dispatch('cart/getCartDetail')

      this.$store.dispatch('wishlist/getAllWishlist')
      this.$store.dispatch('product/getFrequentSearch')
      this.$store.dispatch('address/getCustomerAddresses')
      this.$store.dispatch('address/getBillingAddresses')
      this.$store.dispatch('reserveSlot/getCustomerBlockedSlots')
      this.$store.dispatch('notification/getAllNotification')

      if (this.$route.name !== 'Checkout')
        this.$store.dispatch('payment/getAvailableMethods')
    } else if (!this.isLoggedIn) {
      if (this.currentLocationId) {
        if (['Cart', 'Checkout'].includes(this.$route.name) == false)
          this.$store.dispatch('cart/getCartDetail')
        this.$store.dispatch('reserveSlot/getCustomerBlockedSlots')
        /* if (
          this.$route.name == 'Checkout' &&
          !this.isLoggedIn &&
          this.guestUser
        ) {
          this.$store.dispatch('address/getBillingAddresses', {
            customerID: this.guestUser.customerID,
          })
          if (['scd', 'sd'].includes(this.selectedFulfillmentCodeName)) {
            this.$store.dispatch('address/getCustomerAddresses', {
              customerID: this.guestUser.customerID,
            })
          }
        } */
      }
    }

    this.$store.dispatch('common/getFooterData')

    if (
      !this.isCordova &&
      this.showAppDownloadNotification &&
      !this.$q.platform.is.ios &&
      (!this.isBetaModeActive || this.isBetaAccessCodeValidated)
    )
      this.$store.dispatch('common/getMobileAppData')
    if (this.isCordova && typeof FirebasePlugin !== 'undefined') {
      FirebasePlugin.onMessageReceived((payload) => {
        if (
          payload.hasOwnProperty('tap') &&
          payload.hasOwnProperty('OrderID') &&
          payload.hasOwnProperty('Status') &&
          this.availableStatus.includes(payload.Status)
        ) {
          if (
            this.$route.name != payload.Status ||
            (this.$route.params.orderId &&
              this.$route.name == payload.Status &&
              this.$route.params.orderId != payload.OrderID)
          ) {
            let routeObj =
              payload.Status == 'Reviews'
                ? {
                    name: payload.Status,
                  }
                : {
                    name: payload.Status,
                    params: { orderId: payload.OrderID },
                  }
            this.$router.push(routeObj).catch(() => {})
          }
        }
      })
    }

    if (this.selectedFulfillmentCodeName && this.currentLocationId) {
      if (this.customerExists && this.isLoggedIn) {
        const title =
          'The fulfillment method is unavailable for your selected location (store) currently. How would you like to place your order? '
        if (
          !this.allBusinessFulfillments[this.selectedFulfillmentCodeName]
            .isActive ||
          this.allBusinessFulfillments[this.selectedFulfillmentCodeName]
            ?.isComingSoon
        ) {
          this.$store.commit('common/SET_FULFILLMENT_INACTIVE', true)
          this.changeDialogState({
            dialog: 'changeLocation',
            val: true,
            properties: {
              dialogTitle: title,
            },
          })
        } else {
          this.$store
            .dispatch('locationDetail/fetchLocation', {
              locationId: this.currentLocationId,
            })
            .then((data) => {
              let selectedFulfillment = data?.fulFillments.find(
                (fulFillment) =>
                  fulFillment.codeName === this.selectedFulfillmentCodeName
              )
              this.$store.commit('common/SET_LOCATION_FULFILLMENTS', {
                location: data,
                fulfillment: selectedFulfillment,
              })
              this.$store.commit('location/SET_STATE', {
                key: 'currentLocationDetail',
                value: data,
              })
              if (data?.showSetPreffered) {
                if (
                  !(
                    selectedFulfillment?.available &&
                    !selectedFulfillment?.configuration?.isComingSoon
                  ) &&
                  this.selectedFulfillmentCodeName !== 'sd'
                ) {
                  this.$store.commit('common/SET_FULFILLMENT_INACTIVE', true)
                  this.changeDialogState({
                    dialog: 'changeLocation',
                    val: true,
                    properties: {
                      dialogTitle: title,
                    },
                  })
                }
              } else {
                this.$store.commit('common/SET_FULFILLMENT_INACTIVE', true)
                this.changeDialogState({
                  dialog: 'changeLocation',
                  val: true,
                  properties: {
                    dialogTitle: title,
                  },
                })
              }
            })
        }
      } else {
        if (
          !this.allBusinessFulfillments[this.selectedFulfillmentCodeName]
            ?.isActive ||
          this.allBusinessFulfillments[this.selectedFulfillmentCodeName]
            ?.isComingSoon ||
          (this.selectedFulfillmentCodeName === 'sd' &&
            !this.locationOrShippingZip)
        ) {
          this.$store.dispatch('persisted/setServiceLocation')
          reloadPage()
        } else {
          this.$store
            .dispatch('locationDetail/fetchLocation', {
              locationId: this.currentLocationId,
            })
            .then((data) => {
              if (data?.locationId && data?.showSetPreffered) {
                let selectedFulfillment = data.fulFillments.find(
                  (fulFillment) =>
                    fulFillment.codeName === this.selectedFulfillmentCodeName
                )
                if (
                  (selectedFulfillment.available &&
                    !selectedFulfillment?.configuration?.isComingSoon) ||
                  this.selectedFulfillmentCodeName == 'sd'
                ) {
                  this.$store.dispatch('persisted/setServiceLocation', {
                    location: data,
                    fulfillment: selectedFulfillment,
                  })
                  this.$store.commit('common/SET_LOCATION_FULFILLMENTS', {
                    location: data,
                    fulfillment: selectedFulfillment,
                  })
                  this.$store.commit('location/SET_STATE', {
                    key: 'currentLocationDetail',
                    value: data,
                  })
                } else {
                  this.$store.dispatch('persisted/setServiceLocation')
                  reloadPage()
                }
              }
              /* // SSR EAIV issue fix
              else {
                this.$store.dispatch('persisted/setServiceLocation')
                reloadPage()
                // store.dispatch('common/SET_LOCATION_FULFILLMENTS')
              } */
            })
            .catch(() => {
              this.$store.dispatch('persisted/setServiceLocation')
              reloadPage()
            })
        }
      }
    }
  },
  watch: {
    '$q.screen.width'() {
      let width = this.$q.screen.width

      if (this.$refs.headerBox && this.$refs.headerBox.$el)
        this.headerHight = this.$refs.headerBox.$el.clientHeight

      if (width > 600) this.$store.commit('showcase/updateDrawerWidth', 350)
      else this.$store.commit('showcase/updateDrawerWidth', width - drawerGap)

      if (
        this.$refs.footer &&
        this.$refs.footer.$el &&
        this.showcase.footerHeight != this.$refs.footer.$el.clientHeight
      ) {
        this.$store.commit(
          'showcase/updateFooterHeight',
          this.$refs.footer.$el.clientHeight
        )
      }
    },
  },
  methods: {
    async onEaivSuccess({ routeName }) {
      // load age-restricted categories
      if (ageRestrictedBrowsingPages.includes(routeName) === true) {
        if (this.currentLocationId) {
          this.$store.dispatch('category/getCategories', {
            currentLocationId: this.currentLocationId,
          })
          this.$store.dispatch(
            'category/getTrendingCategory',
            this.currentLocationId
          )
          this.$store.dispatch('product/getTrendingProducts')
        }
      }
    },
  },
}
</script>
<style lang="scss">
.fullscreen.q-drawer__backdrop:not(.no-pointer-events),
.q-dialog .q-dialog__backdrop {
  background-color: rgba(0, 0, 0, 0.5) !important;
}
.q-dialog ~ .q-dialog .q-dialog__backdrop {
  background-color: rgba(0, 0, 0, 0.5) !important;
}
.q-body--prevent-scroll {
  .q-dialog__backdrop {
    backdrop-filter: blur(2px);
  }
}
.mainLayoutBackdrop {
  content: '';
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2000;
  background-color: rgba(0, 0, 0, 0.5) !important;
  backdrop-filter: blur(2px);
  transition: all 0.3s ease-in-out;
}
.container {
  max-width: 1440px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
  &.main-container {
    max-width: 1280px;
    padding-left: 8px;
    padding-right: 8px;
    @media (min-width: 768px) and (max-width: 1290px) {
      .section-component > div.row {
        align-items: center;
      }
    }
  }
}

.container-scroll-area {
  max-width: 100%;
  transition: all 0.3s ease-in-out;
  // height: calc(100vh - 132px);
  // margin-top: 132px;
}
</style>
