<template>
  <q-list>
    <q-item class="notification-item">
      <q-item-section avatar>
        <SvgIcon size="25px" :icon="notification.fulfillmentCodeName" />
      </q-item-section>
      <q-item-section>
        <q-item-section class="q-pr-md">
          <q-item-label class="notification-item-title">{{
            notification.title
          }}</q-item-label>
          <q-item-label caption class="notification-item-desc">{{
            notification.createdAt | formatDate
          }}</q-item-label>
        </q-item-section>
      </q-item-section>
      <q-btn
        dense
        no-caps
        class="notification-item-btn q-px-xs"
        @click="
          viewNotification(
            notification.notificationStatus,
            notification.notificationID
          )
        "
        unelevated
        >View</q-btn
      >
    </q-item>
  </q-list>
</template>

<script>
export default {
  name: 'NotificationDialog',
  data() {
    return {
      dialog: 'NotificationDialog',
    }
  },
  props: {
    notification: {
      type: Object,
    },
  },
  methods: {
    viewNotification(status, id) {
      // VerifiedOrder = 2,
      // Cancelled = 5,
      // Delivered = 6,
      // Rescheduled = 7,
      // UnableToDeliver = 8,
      // OutForDelivery = 9,
      // ReviewOrderProducts = 10
      // PartialDelivered = 11
      // ShippedAndInTransit = 12

      // 2,7,8,9,11,12 => Track Order
      // 5 => Cancel order page
      // 6 => Order complete page
      // 10 => Review Page

      let notificationIds = []
      let currentPath = this.$router.currentRoute.path
      let orderId = this.notification.orderID

      const closeDialog = this.changeDialogState({
        dialog: 'accountDrawer',
        val: false,
      })

      if (
        status === 2 ||
        status === 7 ||
        status === 8 ||
        status === 9 ||
        status === 11 ||
        status === 12
      ) {
        if (currentPath != `/track-order/${orderId}`) {
          this.$router.push({
            name: 'TrackOrder',
            params: { orderId: orderId },
          })
        } else {
          this.closeDialog
        }
      } else if (status === 5 || status === 6) {
        if (currentPath != `/order/${orderId}`) {
          this.$router.push({
            name: 'OrderDetail',
            params: { orderId: orderId },
          })
        } else {
          this.closeDialog
        }
      } else if (status === 10) {
        if (this.$router.currentRoute.name != 'Reviews') {
          this.$router.push({
            name: 'Reviews',
          })
        } else {
          this.closeDialog
        }
      }

      // remove notification from backend and update in store
      if (id) {
        notificationIds.push(id)
      }
      if (notificationIds.length) {
        this.$store.dispatch('notification/removeNotification', notificationIds)
      }
      notificationIds = []
    },
  },
}
</script>

<style lang="scss">
.notification-item {
  align-items: center;
  padding: 10px;
  margin-bottom: 5px;
  &-title {
    font-size: 14px;
    font-weight: bold;
  }
  &-desc {
    font-size: 10px;
  }
  &-btn {
    color: #fff;
    background-color: var(--q-color-secondary);
  }
}
</style>
