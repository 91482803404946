import { render, staticRenderFns } from "./offersModal.vue?vue&type=template&id=6b7dff62"
import script from "./offersModal.vue?vue&type=script&lang=js"
export * from "./offersModal.vue?vue&type=script&lang=js"
import style0 from "./offersModal.vue?vue&type=style&index=0&id=6b7dff62&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QTree from 'quasar/src/components/tree/QTree.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QDialog,QCard,QIcon,QScrollArea,QList,QItem,QItemSection,QItemLabel,QBtn,QSeparator,QTree});qInstall(component, 'directives', {ClosePopup});
