<template>
  <q-footer>
    <q-list>
      <q-item
        v-for="(footerLink, index) in mobileFooterLinks"
        :key="`${footerLink.id}-${index}`"
        :to="footerLink.link ? { name: footerLink.link } : null"
        v-show="
          !footerLink.hasOwnProperty('checkGetter') ||
          (footerLink.checkGetter && $store.getters[footerLink.checkGetter])
        "
        @click="onClickFooterLink(footerLink)"
        clickable
      >
        <q-item-section>
          <q-item-label>
            <span
              class="counter shopping-cart-footer"
              v-if="footerLink.counter && cartTotalItems"
              v-text="cartTotalItems < 100 ? cartTotalItems : '99+'"
            />
            <template v-if="footerLink.id == 'account' && isLoggedIn">
              <UserAvatar />
            </template>
            <template v-else>
              <SvgIcon flat dense color="white" :icon="footerLink.icon" />
            </template>
          </q-item-label>
          <q-item-label caption>
            <div
              :class="
                footerLink.id == 'account' && isLoggedIn
                  ? 'f-account ellipsis'
                  : ''
              "
            >
              {{
                footerLink.id == 'account' && isLoggedIn
                  ? currentUserName
                  : footerLink.title
              }}
            </div>
            <!-- <div
              class="comingsoon-label"
              v-if="footerLink.isComingSoon"
              v-text="footerLink.subTitle"
            /> -->
          </q-item-label>
        </q-item-section>
      </q-item>
    </q-list>
  </q-footer>
</template>

<script>
import { mapGetters } from 'vuex'
import { mobileFooterLinks } from 'src/constants'

export default {
  name: 'Footer',
  data() {
    return {
      mobileFooterLinks,
    }
  },
  computed: {
    ...mapGetters('cart', ['cartTotalItems']),
  },
  methods: {
    onClickFooterLink(footerLink) {
      if (footerLink?.id === 'account' && !this.isLoggedIn) {
        this.changeDialogState({
          dialog: 'accountDrawer',
          val: true,
        })
      } else if (footerLink?.id === 'loyalty') {
        if (this.isLoggedIn) {
          if (this.isCordova)
            this.$store.commit(
              'lnsProgram/SET_SHOW_MOBILE_LOYALTY_PROGRAM',
              true
            )
          else this.$router.push('/loyalty-program#/')
        } else {
          this.changeDialogState({
            dialog: 'accountDrawer',
            val: true,
          })
        }
      }
    },
  },
}
</script>

<style lang="scss">
.comingsoon-label {
  font-size: 8px;
  line-height: 1;
}
.q-footer {
  border-radius: 12px 12px 0 0;
  .q-list {
    padding: 8px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    .q-item {
      flex: 1 0 0;
      text-align: center;
      padding: 8px 2px;
      @include border-radius(8px);
      .q-icon {
        font-size: 1.4rem !important;
      }
      .q-item__label {
        position: relative;
        .counter {
          position: absolute;
          z-index: 1;
          left: 50%;
          bottom: -3px;
          transform: translateX(-50%);
          font-size: 9px;
          background: #f32626;
          color: #fff !important;
          width: 17px;
          height: 17px;
          line-height: 17px;
          text-align: center;
          border-radius: 50%;
        }
      }
      .q-item__label--caption {
        color: #fff;
        /* display: none; */
      }

      &.q-router-link--exact-active {
        &:before {
          content: '';
          background: $primary;
          background: var(--q-color-primary) !important;
          filter: brightness(80%);
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          position: absolute;
          @include border-radius(8px);
        }

        /* background: darken($primary, 10%); */

        /* .q-icon {
          font-size: 1.4rem !important;
        } */

        .q-item__label--caption {
          color: #fff;
          /* display: block; */
        }
      }
      .f-account {
        display: block;
        max-width: 60px;
        margin: 0 auto;
      }
    }
  }
}
</style>
